<template>
  <div :class="{'dark': $store.state.dark}">
      <nav-bar></nav-bar>
      <!-- <va-modal
    v-model="showBatchMRR"
    ok-text="Apply"
    @ok="submit"
  > -->
  <buy-container :power="GLOBALSOLDPOWER" />
  <!-- {{ $root.pmTime }} -->
  <!-- <div class="marquee-container" v-if="Number.isInteger($root.pmTime)">
  <b-container fluid>
    <b-row>
      <b-col>
        <div class="marquee">
          <span class="m-text marquee-text">
            <Timer :seconds="$root.pmTime" />
          </span>
        </div>
      </b-col>
    </b-row>
  </b-container>
</div> -->

  <!-- ok-text="Share" -->
  <!-- ok-only -->
  <!-- cancel-title="Ignore" -->
    <!-- @ok="shareTweet" -->
  <b-modal id="modal-tweet" title="Share to X"
    size="md"
    hide-footer
  >
  
 <div class="cg py-5">
  <b class='cgb '>
  Share your Unique GOLDX NFT Mining Image on X.com and let others know they can participate to
  </b>
 </div>
 <div v-if="selectedInvite !== null">
  <button class="mr-3 btn btn-danger" @click="updateInvite('Ignored')">Ignore</button>
  <button class="mr-3 btn btn-warning" @click="updateInvite(null)">Ask me Later</button>
  <button class="mr-3 btn btn-success" @click="shareTweet('Shared')">Share</button>
  
 </div> 
  </b-modal>
  <b-modal id="modal-mr" title="My Rewards"
    size="lg"
    hide-footer
  >
  <h3>Next Reward:  <span class="float-right">{{Number(availRewards).toFixed(2) }} GOLDX</span> </h3>
  <p class="text-danger">Note: This is only an estimate and is likely to change considerably after a new blockchain scan</p>
  <div v-if="myRewards.length">
    <table class="table">
      <thead>
        <tr>
          <th>Date</th>
          <th>Rewards</th>
          <th>TxHash</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="reward in myRewards" :key="reward.date">
        <td>{{reward.date}}</td>
        <td> {{allRewardsGrouped[reward.date.split(' ')[0]] }} / {{ (reward.value / (10**18)).toFixed(2) }}</td>
        <td> <a :href="'https://explorer.goldxchain.io/tx/'+reward.txHash" target="_blank">txHash</a> </td>
        </tr>
      </tbody>
    </table>
  </div> 
  </b-modal>
  <b-modal id="modal-disc"
  ok-text="OK"
  ok-only
  size="xl"
    @ok="OK"
  >
  <template #modal-title>
    <img src='../assets/images/goldxnftlight.png' style='max-width:133px;' alt=''>
    </template>
 <div class="cg">
  
<b class='cgb'>Legal Disclaimer for Fuse.gold</b>
  <p> 
Fuse.gold (the "Company") is engaged in the sale of digital mining rights, utilizing [FORTG] NFTs as a means of documentation and transfer. It is important for all users and potential purchasers to understand that the Company does not engage in the sale of cryptocurrencies or NFTs in their commonly understood forms. 
  </p>

<p>
It is crucial to note that once [FORTG] NFTs are endowed with GOLDX Mining Rights, such rights become immutable and are not subject to reversal or alteration. Ownership and associated mining rights of the GOLDX NFTs can be transferred to another party, but such transfer must be conducted exclusively through the nft.fortune.gold platform. Transfers attempted through MetaMask or any platform other than the specified one will not be recognized by the Company.
</p>

<p>
Furthermore, the Company anticipates the future sale of a 100% gold-backed token, [FUSEG]. However, as of this moment, the Company's offerings are limited to digital mining rights as described above.
</p>
<p>
All participants are advised to conduct their transactions in compliance with this disclaimer and the terms and conditions set forth by the Company. The Company reserves the right to refuse the sale of mining rights to any individual or entity at its sole discretion.
</p>
 </div> 
  </b-modal>
  <b-modal id="modal-wrp"
  title="Wrap Multiple nfts into Single" 
  ok-text="Apply"
  hide-footer
  >
    <!-- <h3 class="va-h3">
      Batch/Custom Mining Rights Request 
    </h3> -->
   
    <!-- <h3>Request Mining Rights</h3> -->
   

    <div class="form-group" >
      <label for="">Select Target ID</label>
      <select name="" id="" class="form-control" v-model="WRPid" >
      <option :value="n.id" v-for="n in avaiNFTsIDS" :key="n.id">
{{ n.id }}
      </option>
      </select>
    </div>

    <div class="form-group">
      <label for="">Select Miners IDs(Press and hold Ctrl to select multiple)</label>
      <select name="" id="" class="form-control" v-model="WRPmins" multiple>
      <option :value="n.id" v-for="n in NFTssSep.min" :key="n.id">
{{ n.id }}
      </option>
      </select>
    </div>

    <div class="form-group">
      <label for="">Select Prospectors IDs(Press and hold Ctrl to select multiple)</label>
      <select name="" id="" class="form-control" v-model="WRPpros" multiple>
      <option :value="n.id" v-for="n in NFTssSep.pros" :key="n.id">
{{ n.id }}
      </option>
      </select>
    </div>
    <button class="button btn btn-primary d-block mx-auto" @click="requestWrap">Request Wrap</button>
    <span class="text-danger" v-if="error.length">{{error}}</span>
    
    
  </b-modal>
  <b-modal id="modal-1"
  size="lg"
  title="Mining Rights Request" 
  hide-footer
  >
    <h3 class="va-h3">
      Batch/Custom Mining Rights Request 
    </h3>
    <p>
      Pending NFTs in Requests: <span class="float-right">{{ pendNFTs }}</span>
    </p>
    <p>
      Available Non-Mineable NFTs: <span class="float-right">{{ avaiNFTsIDS.length }}</span>
    </p>
    <p>
      Avaialble Mineable NFTs: <span class="float-right">{{ avaiMinNFTs.length }}</span>
    </p>
    <h3>Request Mining Rights</h3>
    <div class="form-group">
      <label for="">Select Class</label>
      <select name="" id="" class="form-control" v-model="slClass">
      <option :value="op" v-for="op in classes" :key="op">{{ op }}</option>
    </select>
    </div>

    <div class="form-group" v-if="slClass !== 'Custom'">
      <label for="">Select IDs(Press and hold Ctrl to select multiple)</label>
      <select name="" id="" class="form-control" v-model="slQty" multiple>
      <option :value="n.id" v-for="n in avaiNFTsIDS" :key="n.id">
{{ n.id }}
      </option>
      </select>
      <button class="d-block mx-auto cgb green-button st-shadow-green my-4 px-4" @click="submit" :disabled="slQty.length < 1">Submit</button>
      <!-- <input type="number" name="" id="" :max="avaiNFTs" v-model="slQty" class="form-control"> -->
    </div>
    <div class="form-group" v-else>
      <label for="" class="d-block">Value(${{slUSD}})
<span class="float-right"> Estimated Power(%): <b> {{(slUSD * PDP).toFixed(5) }} </b>  </span>
      </label>
      <b-form-input type='range' v-model="slUSD" :min="500" :max="100000" :step="100"></b-form-input>
      <span>MIN: $500</span>
      <span class="float-right">MAX: $100000</span>
      <label for="" class="d-block">Select NFT ID</label>
      <select name="" id="" class="form-control" v-model="slID" >
      <option :value="n.id" v-for="n in avaiNFTsIDS" :key="n.id">
{{ n.id }}
      </option>
      </select>
      
      <button class="d-block mx-auto cgb green-button st-shadow-green my-4 px-4" @click="submit" :disabled="slID < 1">Submit</button>
      <!-- <input type="number" name="" id="" :max="avaiNFTs" v-model="slQty" class="form-control"> -->
    </div>
    
  </b-modal>
      <div id="sec-one" class="bg-light-extra px-2 px-lg-5">
        <div class="row p-0 m-0">
          <div class="col-lg-6 p-0 m-0">
            <div class="bg-light mr-lg-4 pt-4 my-2 cornered st-shadow stats-fonts p-stats-container">
              <!-- <p class="mb-3 txGoldNew cgb">GOLDX STATS </p> -->
              
  <b-button v-b-toggle.collapse-1 variant="txGoldNew">GOLDX STATS &#9660;</b-button>
    <b-collapse id="collapse-1">
        <p class="mb-1"><span class="cg">REFERRALS GOLDX</span> <span class="float-right text-digit txGoldNew cgb"> 0.0000 GOLDX </span> </p>
              <p class="mb-1"><span class="cg">GOLDX REWARDED FROM PLATFORM BUYS</span> 
                <span class="float-right text-digit txGoldNew cgb" v-if="GOLDX_USERS[userWallet] && GOLDX_USERS[userWallet].buy"> 
                  {{(GOLDX_USERS[userWallet].buy / 10**GOLDX_DECIMALS).toFixed(4) }} GOLDX </span> 
                <span class="float-right text-digit txGoldNew cgb" v-else> 0.0000 GOLDX </span> 
              </p>
              <p class="mb-1"><span class="cg">GOLDX REWARDED FROM PLATFORM SELLS</span> 
                <span class="float-right text-digit txGoldNew cgb" v-if="GOLDX_USERS[userWallet] && GOLDX_USERS[userWallet].sell"> 
                  {{(GOLDX_USERS[userWallet].sell / 10**GOLDX_DECIMALS).toFixed(4) }} GOLDX </span>
                <span class="float-right text-digit txGoldNew cgb" v-else> 0.0000 GOLDX </span>
               </p>
              <p class="mb-1"><span class="cg">GOLDX REWARDED FROM PLATFORM EXCHANGE </span>
                <span class="float-right text-digit txGoldNew cgb" v-if="GOLDX_USERS[userWallet] && GOLDX_USERS[userWallet].exchanged"> {{(GOLDX_USERS[userWallet].exchanged / 10**GOLDX_DECIMALS).toFixed(4) }} GOLDX </span>
                <span class="float-right text-digit txGoldNew cgb" v-else> 0.0000 GOLDX </span>
               </p>
              <p class="mb-1"><span class="cg">GOLDX REWARDED FROM DEX BUYS </span>
                <span class="float-right text-digit txGoldNew cgb" v-if="PTOTAL_GOLDX_REWARDED_FROM_DEX_BUYS"> {{(PTOTAL_GOLDX_REWARDED_FROM_DEX_BUYS / 10**GOLDX_DECIMALS).toFixed(4) }} GOLDX </span>
                <span class="float-right text-digit txGoldNew cgb" v-else> 0.0000 GOLDX </span>
               </p>
              <p class="mb-1"><span class="cg">GOLDX REWARDED FROM DEX SELLS</span> 
                <span class="float-right text-digit txGoldNew cgb" v-if="PTOTAL_GOLDX_REWARDED_FROM_DEX_SELLS"> {{(PTOTAL_GOLDX_REWARDED_FROM_DEX_SELLS / 10**GOLDX_DECIMALS).toFixed(4) }} GOLDX </span>
                <span class="float-right text-digit txGoldNew cgb" v-else> 0.0000 GOLDX </span>
               </p>
              <p class="mb-1"><span class="cg">GOLDX EXCHANGED TO FUSEG</span> <span class="float-right text-digit txGoldNew cgb"> 0.0000 GOLDX </span> </p>
              <p class="mb-1"><span class="cg">GOLDX EXCHANGED TO NFTs</span> <span class="float-right text-digit txGoldNew cgb"> 0.0000 GOLDX </span> </p>
    </b-collapse>
              
              <p class="mt-3"> <b><span class="cgb">GOLDX</span></b> <span class="float-right text-digit txGoldNew cgb" style="font-size:128%;"> 0.0000 GOLDX </span> </p>
              <p class="mt-3"> <b><span class="cgb">GOLDX VIP</span></b> <span class="float-right text-digit txGoldNew cgb" style="font-size:128%;"> 0.0000 GOLDX </span> </p>
              <p class="mt-3"> <b><span class="cgb">FUSEG</span></b> <span class="float-right text-digit txGoldNew cgb" style="font-size:128%;"> 0.0000 GOLDX </span> </p>
              <p class="mt-3"> <b><span class="cgb">CLAIMABLE GOLDX</span></b> <span class="float-right text-digit txGoldNew cgb" style="font-size:128%;"> 0.0000 GOLDX </span> </p>
              
              <!-- <span class="d-block mx-auto cgb green-button st-shadow-green my-4" style="margin-bottom:26px !important;"> -->
                <!-- Claim -->
                <span class="d-block mx-auto cgb green-button st-shadow-green my-4" v-b-modal.modal-1 style="margin-bottom:26px !important;">
                Mining Rights 
              </span>
              <!-- </span> -->
              <!-- <img src="../assets/images/1x/claim.png" class="d-block mx-auto my-4" alt="" srcset="" style="max-width:55%;cursor: pointer;"> -->

            </div>
          </div>
          <div class="col-lg-6 p-0 m-0">
            <div class="clGold px-4 px-lg-4 pt-4 pb-1 mt-2 ml-lg-4 cornered-g st-shadow ">
              <img src="../assets/images/fgblack.png" style="max-width:100px; float: right;" alt="" srcset="">
              <p class="mb-3 text-white cgb">FUSEG WALLET </p>

              <p class="text-white" style="    font-size: 76%;
    margin-bottom: 1px !important;"> <span class="cg">{{PFUSEG_BALANCE}} FUSE G</span> <span class="pl-5 text-digit d-inline-block ml-1">  
                <span class="cg half-white">in Wallet (0000.00$)</span> 
              </span> </p>

              <p class="text-white" style="    font-size: 76%;
    margin-bottom: 1px !important;"><span class="cg">{{PGOLDX_BALANCE}} GOLDX</span> <span class="pl-5 text-digit"> 
                <span class="cg half-white"> in Wallet ({{(PFUSEG_BALANCE * Number(FUSEG_PRICE)).toFixed(2) }}$) </span> 
              </span> </p>
              <div>
                <div class="input-cont-div bg-white mt-3">
                  <input type="text" name="" id="nm" style="display: inline-block;width: 80%;" placeholder="Enter FUSEG Amount">
                  <span class="cgb green-button-em"> Add FUSEG</span>
                </div>
                <div class="input-cont-div bg-white mt-3">
                  <input type="text" name="" id="nm" style="display: inline-block;width: 80%;" placeholder="Enter GOLDX Amount">
                  <span class="cgb green-button-em"> Add GOLDX</span>
                </div>
                <div class="input-cont-div bg-white mt-3 mb-2">
                  <input type="text" name="" id="nm" style="display: inline-block;width: 80%;" placeholder="Set Slippage">
                  <span class="cgb green-button-em"> Set Slippage</span>
                </div>
                <!-- <span class="d-block mx-auto cgb green-button st-shadow-green mt-4 mb-3">
                Confirm and Mine GOLDX VIP
              </span> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
    
  </div>
      <div id="sec-two" class="bg-light mx-lg-5 py-4 cornered st-shadow mt-4" style="font-size: 72%;">
        <h4 class="m-4 mnfts">MY NFTs

          <img src="../assets/images/goldxnftlight.png" alt="" style="    float: right;
    width: 237px;
    max-width: 55%;
    margin-top: -17px;" srcset="">
        </h4>
        <!-- <div class="row m-0 p-0"> -->
          
         
          <!-- <div class="col-xl-3 col-lg-4 col-sm-6 m-0 px-3 my-2" v-for="nft in NFTss" :key="nft.id"> -->
            <!-- <nft-comp :nft="nft" :ids="pendNFTsIDs"></nft-comp> -->
          <!-- </div> -->
          
        <!-- </div> -->
        <div class="row m-0 p-0">
          <div class="col-lg-6 col-md-12 d-none">
            <p class="cgb text-center">NFTS MINING RIGHTS REQUESTS ALLOWED BY YOUR PURCHASES</p>
            <div class="row m-0 p-0">
              
              <div class="col-4">
                <span class="text-center d-block">Miners</span>
                <span class="text-center d-block"> {{ (mRights.Miners !== undefined ) ? mRights.Miners.totalQty : 0 }} </span>
              </div>
              <div class="col-4">
                <span class="text-center d-block">Refienrs</span>
                <span class="text-center d-block"> {{ (mRights.Refiners !== undefined ) ? mRights.Refiners.totalQty : 0 }}</span>
              </div>
              <div class="col-4">
                <span class="text-center d-block">Prospectors</span>
                <span class="text-center d-block"> {{ (mRights.Prospectors !== undefined ) ? mRights.Prospectors.totalQty : 0 }} </span>
              </div>
            </div>
            <p class="cgb text-center">APPROVED NFTS MINING RIGHTS REQUESTS </p>
            <div class="row m-0 p-0">
              
              <div class="col-4">
                <span class="text-center d-block">Refienrs</span>
                <span class="text-center d-block">{{ PSTATS.ref }}</span>
              </div>
              <div class="col-4">
                <span class="text-center d-block">Miners</span>
                <span class="text-center d-block">{{ PSTATS.min }}</span>
              </div>
              
              <div class="col-4">
                <span class="text-center d-block">Prospectors</span>
                <span class="text-center d-block">{{ PSTATS.pros }}</span>
              </div>
            </div>
            <p class="cgb text-center">PENDING NFTS MINING RIGHTS REQUESTS</p>
            <!-- <p class="cgb">PENDING REQUESTS MINING RIGHTS</p> -->
            <div class="row m-0 p-0">
              
              <div class="col-4">
                <span class="text-center d-block">Miners</span>
                <span class="text-center d-block">{{ pendMin.length }}</span>
              </div>
              <div class="col-4">
                <span class="text-center d-block">Refienrs</span>
                <span class="text-center d-block">{{ pendRef.length }}</span>
              </div>
              <div class="col-4">
                <span class="text-center d-block">Prospectors</span>
                <span class="text-center d-block">{{ pendPros.length }}</span>
              </div>
            </div>
          </div>
          <div class="col-lg">
            <div class="row p-0 m-0 position-relative">
              <div class="col-lg-12">
              <p class="mb-3 txGoldNew cgb text-center">Total Mining Power Available</p>
              </div>
              <div class="col-lg-4">
              <p class="mb-3 txGoldNew cgb text-center">Global</p>
              <div class="position-absolute">
                High Units: {{refSold}}/100 <br>
                Medium Units: {{soldMiners}}/450,000<br>
                Low Units {{soldPros}}/9,000,000<br>
              </div>
                <apexchart type="radialBar" height="250" :options="circleReferralsM" :series="[ Number(GLOBALSOLDPOWER).toFixed(4) ]"></apexchart>
              </div>
              <div class="col-lg-4">
              <p class="mb-3 txGoldNew cgb text-center" v-if="getLastRound !== null">Current Round({{getLastRound.key}})</p>
                <apexchart type="radialBar" height="250" :options="circleReferralsM" :series="[ Number(getLastRound.data.power).toFixed(4) ]"></apexchart>
              </div>
              <div class="col-lg-4" v-if="totalSales.total < 100">
              <p class="mb-3 txGoldNew cgb text-center" v-if="getLastRound !== null">PROMO Status</p>
                <apexchart type="radialBar" height="250" :options="circleReferralsM" :series="[ Number(totalSales.total).toFixed(3) ]"></apexchart>
              </div>
         
              <div class="col-lg-12">
                
              <h4 class="mb-3 text-center" style="color: #b3b3b3;font-size:23px;"> 
                <span class="text-secondary">${{remainUSD.toLocaleString() }}</span> / <span>${{comma(TUSD)}}</span>  </h4>
              </div>
            </div>
           
          </div>
          <div class="col-lg" v-if="userWallet">
            <div class="row">
              <div class="col-lg-12">
              <p class="mb-3 txGoldNew cgb text-center">Total Mining Power</p>
              </div>
              <div class="col-lg-6">
                <!-- <div class="d-block mx-auto" style="max-width: 300px;"> -->
              <p class="mb-0 txGoldNew cgb text-center">Your total Mining power <br> Round {{getLastRound.key}}</p> 
                <apexchart type="radialBar" height="250" :options="circleReferrals" :series="[allPower.toFixed(5)]"></apexchart>
            <!-- </div> -->
              </div>
              <div class="col-lg-6">
                <p class="mb-3 txGoldNew cgb text-center">Your total Global Mining power</p>
                <apexchart type="radialBar" height="250" :options="circleReferrals" :series="[allPowerGlobal.toFixed(5)]"></apexchart>
              
              </div>
            </div>
          </div>
          <!-- <div class="col-lg-4" v-if="userWallet"> -->
            <!-- <div class="d-block mx-auto" style="max-width: 300px;"> -->
              <!-- </div> -->
          <!-- </div> -->
          
          <!-- <div class="col-lg-4 pt-3"> -->
            
          <!-- </div> -->
          <div class="col-lg-12 pt-5">
            <div class="row">
              
              <div class="col-lg-2">

                <p class="mb-3 txGoldNew cgb text-center">GENESIS Refiner </p>
              <h3 class="text-center">
                <a href="https://goldxscan.com/address/0x596ED45C164c9daA05f5c3Ed04965406A3e8a421/transactions" target="_blank" class="cgbb"> 
                  <span v-if="$store.state.GRefiner"> {{($store.state.GRefiner )}}</span>
                </a>  
              </h3>
              </div>
              <div class="col-lg-2">
                
              <p class="mb-3 txGoldNew cgb text-center">Alpha Refiner </p>
              <h3 class="text-center">
                <a href="https://goldxscan.com/address/0x626f8eD670Da7D810Dd4EA7304dcf3edC12aA7B2/transactions" target="_blank" class="cgbb"> 
                  <span v-if="$store.state.ARefiner"> {{($store.state.ARefiner )}}</span>
                </a>  
              </h3>
              </div>
              <div class="col-lg-4">
                <p class="mb-3 txGoldNew cgb text-center" style="font-size:170%;">Max Mining Refiner </p>
              <h3 class="text-center">
                <a href="https://explorer.goldxchain.io/address/0x6b8cc9A57f3cCb521DFB93Cbf7134c6c130edF60" target="_blank" class="cgbb"> 
                  <span style="font-size:140%;" v-if="$store.state.PMRewards"> {{$store.state.PMRewards}}</span>
                </a>  
              </h3>
              </div>
              <div class="col-lg-2">
                
              <p class="mb-3 txGoldNew cgb text-center">OG Refiner  </p>
              <h3 class="text-center">
                <a href="https://goldxscan.com/address/0xCbE8CC5500e8C0f8D2b7D46ada7A911Ac51550e1/transactions" target="_blank" class="cgbb"> 
                  <span v-if="$store.state.OGRefiner"> {{($store.state.OGRefiner )}}</span>
                </a>  
              </h3>
              </div>
              <div class="col-lg-2">
                
              <p class="mb-3 txGoldNew cgb text-center">GOLDX Refiner   </p>
              <h3 class="text-center">
                <a href="https://goldxscan.com/address/0x825B52AE79EE8828AcC4cD75e8aab65f2F0875E7/transactions" target="_blank" class="cgbb"> 
                  <span v-if="$store.state.GXRefiner"> {{($store.state.GXRefiner )}}</span>
                </a>  
              </h3>
              </div>
            </div>
            <div class="d-block mx-auto">
<!--               
              <p class="mb-3 txGoldNew cgb text-center">Refiner rewards Available</p>
              <h3 class="text-center"> 
                <a href="https://explorer.goldxchain.io/address/0x48032DF40A94c9C472706863B1eef8b181c79293" target="_blank" class="cgbb"> {{$store.state.refRewards}} </a>
              </h3> -->

              




              </div>
          </div>
         
          <div class="col-lg-12 px-4" style="color: black;">
            <div class="d-none">
              <div class="" v-if="NFTss.length">
            <VueSlickCarousel
            v-bind="settings"
            
            >
      <div v-for="nft in NFTss" :key="nft.id">
        <nft-comp :nft="nft" :ids="pendNFTsIDs"></nft-comp>
      
      </div>
      <template #prevArrow="">
      <!-- <div > -->
        <img class="custom-arrow slick-arrow slick-prev" src="../assets/left.png" alt="" srcset="" style="width: 55px;height:60px;">
        <!-- Prev -->
        <!-- {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }} -->
      <!-- </div> -->
    </template>
    <template #nextArrow="">
      <!-- <div > -->
        <img class="custom-arrow slick-arrow slick-next" src="../assets/left.png" alt="" srcset="" style="width: 55px;height:60px;transform: rotate(180deg);margin-top: -31px;">

        <!-- Next -->
        <!-- <img src="../assets/left.png" alt="" srcset=""> -->
        <!-- {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }} -->
      <!-- </div> -->
    </template>
    <!-- <template #customPaging="page"> -->
      <!-- <div class="custom-dot"> -->
        <!-- {{ page }} -->
        <!-- <img src="../assets/left.png" alt="" srcset=""> -->

      <!-- </div> -->
    <!-- </template> -->
      
    </VueSlickCarousel>
          </div>
            </div>

            <div class="" v-if="NFTss.length">
              <button class="scroll-button lefts" @click="scrollLeft"> 
              <img src="../assets/images/left.png" style="max-width:55px;margin-left: -6px;" alt="" srcset="">
              </button>
              <button class="scroll-button rights" @click="scrollRight"> 
                <img src="../assets/images/right.png" style="max-width:55px;margin-left: -4px;" alt="" srcset="">
              </button>
              <div class="scrollable-container" ref="scrollContainer" @touchstart="handleTouchStart" @touchmove="handleTouchMove" @touchend="handleTouchEnd">
                
              <div class="scrollable-content" ref="content" >
                <div v-for="nft in NFTss" :key="nft.id" class="d-inline-block pl-3" style="max-width:250px;">
                  
              <nft-comp :nft="nft" :ids="pendNFTsIDs"></nft-comp>
            </div>
              </div>
              
            </div>
              
            </div>
            
          </div>
          <div class="col-lg-6 p-0 m-0">
            <div class="bg-light mr-lg-4 cornered stats-fonts-nor p-norm">
              <p class="mb-3 txGoldNew cgb">NFT STATS </p>
              <p class="mb-3 txGoldNew cgb">APPROVED NFTS MINING RIGHTS REQUESTS </p>
              <p class="mb-1"><span class="cg">Refiners</span> <span class="float-right text-digit txGoldNew cgb"> {{ PSTATS.ref }}</span> </p>
              <p class="mb-1"><span class="cg">Miners</span> <span class="float-right text-digit txGoldNew cgb"> {{PSTATS.min}} </span> </p>
              <p class="mb-1"><span class="cg">Prospectors</span> <span class="float-right text-digit txGoldNew cgb"> {{ PSTATS.pros }} </span> </p>

              <p class="mb-3 txGoldNew cgb">PENDING NFTS MINING RIGHTS REQUESTS </p>
              <p class="mb-1"><span class="cg">Refiners</span> <span class="float-right text-digit txGoldNew cgb"> {{ pendRef.length }} </span> </p>
              <p class="mb-1"><span class="cg">Miners</span> <span class="float-right text-digit txGoldNew cgb"> {{ pendMin.length }} </span> </p>
              <p class="mb-1"><span class="cg">Prospectors</span> <span class="float-right text-digit txGoldNew cgb"> {{ pendPros.length }} </span> </p>
              
            </div>
          </div>
          
          <!-- <div class="col-lg-6 col-md-12">
            
          </div> -->

          <div class="col-lg-6 col-md-12">
            <!-- <span class="d-block mx-auto cgb green-button st-shadow-green my-4" v-if="availRewards !== 0">
              Total Rewards: {{Number(availRewards).toFixed(2) }} GOLDX
              </span> -->
              <!-- <span class="d-block mx-auto cgb green-button st-shadow-green my-4" v-if="availRewardsMiners !== 0"   @click="claimMiners()">
                Claim All Miners ({{ availRewardsMiners }} GOLDX)
              </span> -->
<!--              
              <span class="d-block mx-auto cgb green-button st-shadow-green my-4" v-if="availRewardsPros !== 0" @click="claimProspectors()">
                Claim All Prospectors ({{ availRewardsPros }} GOLDX)
              </span> -->
              <!-- <span class="d-block mx-auto cgb green-button st-shadow-green my-4" 
              @click="claimSingle(custom.id)" v-for="custom in availRewardsCustom" :key="custom.id">
                Claim #{{custom.id}} ({{ (Number(custom.rewards)/10**GOLDX_DECIMALS).toFixed(2) }} GOLDX)
              </span> -->
              <span class="d-block mx-auto cgb green-button st-shadow-green my-4" v-b-modal.modal-xl>
                Purchase Mining Rights 
              </span>
              <span v-if="userWallet" class="d-block mx-auto cgb green-button st-shadow-green my-4" v-b-modal.modal-mr>
                My Rewards 
              </span>  

          </div>
          
        </div>


      </div>
      <div id="sec-threes" class=" px-2 px-lg-5 mt-5">
        <div class="row m-0 p-0">
          <div class="col-md-12 px-0 px-lg-5">
            <div class="bg-light mx-2 px-1 px-lg-4 pt-4 my-2 cornered st-shadow">
              <p class="mb-3 txGoldNew cgb">SEARCH NFTs STATS </p>
              <p class="mpro cg"> <b>NFT</b> add NFT ids and click search to find out there current stats </p>
              <b-form-tags input-id="tags-basic" placeholder="Input ID and press Enter(Numbers Only)" v-model="IDS"></b-form-tags>
              
<div v-if="userWallet">
  <span class="d-block mx-auto cgb green-button st-shadow-green my-4"  @click="searchStats" v-if="IDS.length">
                SEARCH
              </span>
              <span class="d-block mx-auto cgb green-button st-shadow-green my-4"   v-else>
                ADD ATLEAST 1 ID
              </span>
</div>
<div v-else>
  <span class="d-block mx-auto cgb green-button st-shadow-green my-4"  v-if="IDS.length">
                Please Connect Wallet
              </span>
              <span class="d-block mx-auto cgb green-button st-shadow-green my-4"   v-else>
                ADD ATLEAST 1 ID
              </span>
</div>
<table v-if="NSTATS.length" class="table" style="font-size:74%;">
              <thead>
                <tr>
                  <th scope="col"> ID</th>
                  <th scope="col">Class</th>
                  <th scope="col">Power</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="nt in NSTATS" :key="nt.id" scope="row">
                  <td> 
                    <span class="px-1 d-block text-success  cgb" v-if="nt.approved"> {{nt.id}} </span>
                    <span class="px-1 d-block text-danger  cgb" v-else> {{nt.id}} </span>
                   </td>
                  <td v-if="nt.approved">
                    <span class="px-1 d-block  cgb" v-if="nt.stats[0][2] == '2'"> Miner </span>
                    <span class="px-1 d-block  cgb" v-else-if="nt.stats[0][2] == '3'"> Prospector </span>
                    <span class="px-1 d-block  cgb" v-else-if="nt.stats[0][2] == '1'"> Refiner </span>
                    <span class="px-1 d-block  cgb" v-else> Custom/Dual </span>
                  </td>
                  <td v-if="nt.approved">
                    <span class="px-1 d-block  cgb" v-if="nt.stats[0][2] == '1'"> 
                      <!-- <b-badge v-if="nt.stats[1] == true || nt.stats[0][2] == 0"> {{0.0072 * ( (nt.stats[3]) ? Number(nt.stats[3]) : 1 )  }} % ({{( (nt.stats[3]) ? Number(nt.stats[3]) : 1 )}}X)</b-badge> -->
                    <b-badge >  Global: 0.1888%</b-badge>
                    <b-badge >  Round: 3.8%</b-badge>
                    </span>
                    <span class="px-1 d-block  cgb" v-if="nt.stats[0][2] == '2'"> 
                      <b-badge v-if="nt.stats[1] == true || nt.stats[0][2] == 0"> {{0.0072 * ( (nt.stats[3]) ? Number(nt.stats[3]) : 1 )  }} % ({{( (nt.stats[3]) ? Number(nt.stats[3]) : 1 )}}X)</b-badge>
                    <b-badge v-else> 0.0072 %</b-badge>
                    </span>
                    <span class="px-1 d-block  cgb" v-if="nt.stats[0][2] == '3'"> 
                      <b-badge v-if="nt.stats[1] == true || nt.stats[0][2] == 0"> {{0.00036 * ( (nt.stats[4]) ? Number(nt.stats[4]) : 1 )  }} % ({{( (nt.stats[4]) ? Number(nt.stats[4]) : 1 )}}X)</b-badge>
                      <b-badge v-else> 0.00036 % </b-badge>
                    </span>
                    <span class="px-1 d-block  cgb" v-if="nt.stats[0][2] == '0'"> 
                      <span v-if="nt.stats[3] !== '0' &&  nt.stats[4] !== '0'">
                        <b-badge> Pros-{{0.00036 * ( (nt.stats[4]) ? Number(nt.stats[4]) : 1 )  }} % ({{( (nt.stats[4]) ? Number(nt.stats[4]) : 1 )}}X)</b-badge>
                        <b-badge> Min-{{0.0072 * ( (nt.stats[3]) ? Number(nt.stats[3]) : 1 )  }} % ({{( (nt.stats[3]) ? Number(nt.stats[3]) : 1 )}}X)</b-badge>

                      </span>
                      <!-- <span v-else> 0.00036 % </span> -->
                    </span>
                  </td>
                  <!-- <td>{{NSTATS}}</td> -->
                </tr>
              </tbody>
              </table>
              

            </div>
          </div>
          
         
          
        </div>
      </div>
      <div id="sec-three" class=" px-2 px-lg-5 mt-5">
        <div class="row m-0 p-0">
          <div class="col-lg-6 col-md-12 px-lg-5">
            <div class="bg-light mx-2 px-4 pt-4 my-2 cornered st-shadow">
              <p class="mb-3 txGoldNew cgb">EXCHANGE MINING NFT TO GOLDX </p>
              <p class="mpro cg"> <b>NFT</b> write the code of the nft you want to exchange below </p>
              <div class="number-input-container">
                <input type="number" class="number-input" placeholder="#777">
              </div>
              <div>
                <span class="d-block mx-auto arrow-cover">
                <img src="../assets/images/1x/arrow-d.png" class="d-block mx-auto" alt="" srcset="" style="max-width:43%;">
              </span>
              </div>
              <p class="mb-1 cgb">RECEIVED GOLDX <span class="float-right text-digit txGoldNew">Balance 0.0000 </span> </p>
              <div class="number-input-container">
                <div class="number-input">
                  <span class="d-block text-center" style="">777.00</span>
                  <span class="d-block text-center txGoldNew">777 USD</span>
                </div>
                <!-- <input type="number" class="number-input" placeholder="#777"> -->
              </div>
              <span class="d-block mx-auto cgb green-button st-shadow-green my-4">
                Exchange
              </span>

            </div>
          </div>
          <div class="col-lg-6 col-md-12 px-lg-5">
            <div class="bg-light mx-2 px-4 pt-4 my-2 cornered st-shadow">
              <p class="mb-3 txGoldNew cgb">REDEEM MINING NFT TO FUSEG </p>
              <p class="mpro"> <b>NFT</b> write the code of the nft you want to redeem below </p>
              <div class="number-input-container">
                <input type="number" class="number-input" placeholder="#777">
              </div>
              <div>
                <span class="d-block mx-auto arrow-cover">
                <img src="../assets/images/1x/arrow-d.png" class="d-block mx-auto" alt="" srcset="" style="max-width:43%;">
              </span>
              </div>
              <p class="mb-1 cgb">RECEIVED GOLDX <span class="float-right text-digit txGoldNew">Balance 0.0000 </span> </p>
              <div class="number-input-container">
                <div class="number-input">
                  <span class="d-block text-center" style="">777.00</span>
                  <span class="d-block text-center txGoldNew">777 USD</span>
                </div>
                <!-- <input type="number" class="number-input" placeholder="#777"> -->
              </div>
              <span class="d-block mx-auto cgb green-button st-shadow-green my-4">
                Redeem
              </span>

            </div>
          </div>
         
          
        </div>
      </div>
      <div id="sec-four" class="px-lg-5 mt-5">
        <div class="row p-0 m-0">
          <div class="col-lg-6 col-md-12 px-lg-5">
            <div class="bg-light mx-2 px-4 pt-4 my-2 cornered st-shadow">
              <p class="mb-3 txGoldNew cgb">AVAILALBE GOLDX REWARDS </p>
              <p class="cg">Claimable Reward in All Mineable</p>
              <!-- <p class="mb-1">RECEIVED GOLDX <span class="float-right text-digit txGoldNew">Balance 0.0000 </span> </p> -->
              <div class="number-input-container">
                <input type="number" class="number-input-disabled" placeholder="777 GOLDX" disabled>
              </div>
              
              <span class="d-block mx-auto cgb green-button st-shadow-green my-4">
                Claim
              </span>

            </div>
          </div>
          <div class="col-lg-6 col-md-12 px-lg-5">
            <div class="bg-light mx-2 px-4 pt-4 my-2 cornered st-shadow">
              <p class="mb-3 txGoldNew">AVAILALBE FUSEG REWARDS </p>
              <p class="cg">Claimable Reward in All Mineable</p>
              <div class="number-input-container">
                <input type="number" class="number-input-disabled" placeholder="777 FUSEG" disabled>
              </div>
              
              <span class="d-block mx-auto cgb green-button st-shadow-green my-4">
                Claim
              </span>

            </div>
          </div>
         
          
        </div>
      </div>
      <div id="sec-five" class="bg-light-extra px-lg-5 mt-5">
        <div class="row m-0 p-0">
          <div class="col-lg-6 col-md-12 px-lg-5">
            <div class="bg-light mx-2 px-4 pt-4 my-2 cornered st-shadow">
              <p class="mb-5 txGoldNew cgb">CONVERT GOLDX VIP TO GOLDX </p>
              <p class="mb-1">GOLDX VIP <span class="float-right text-digit txGoldNew">Balance 0.0000 </span> </p>
              <div class="number-input-container">
                <div class="number-input" style="font-size: 98%;
    line-height: 22px;" placeholder="#777"> 
                  <span class="d-block text-right">0.1234</span>
                  <span class="text-right d-block txGoldNew" style="font-size: 74%;"> ~55.05 USD </span>
                  <div class="float-right">
                    <button class="btn btn-sm g-round">25%</button>
                    <button class="btn btn-sm g-round">50%</button>
                    <button class="btn btn-sm g-round">75%</button>
                  </div>
                </div>
              </div>
              
              <div>
                <span class="d-block mx-auto arrow-cover">
                <img src="../assets/images/1x/arrow-d.png" class="d-block mx-auto" alt="" srcset="" style="max-width:43%;">
              </span>
              </div>
              <p class="mb-1">GOLDX<span class="float-right text-digit txGoldNew">Balance 0.0000 </span> </p>

              <div class="number-input-container">
                <div class="number-input" style="font-size: 98%;
    line-height: 22px;" placeholder="#777"> 
                  <span class="d-block text-right">0.1234</span>
                  <span class="text-right d-block txGoldNew" style="font-size: 74%;"> ~55.05 USD </span>
                  <div class="float-right">
                    <button class="btn btn-sm g-round">25%</button>
                    <button class="btn btn-sm g-round">50%</button>
                    <button class="btn btn-sm g-round">75%</button>
                  </div>
                </div>
              </div>
                <div class="row">
                  <div class="col-lg-7 col-md-12">
                    <span class="txGoldNew"> Price </span>
                    <span class="float-right"> 0.33456 GOLDX per GOLDX VIP </span>
                  </div>
                  <div class="col-lg-5 col-md-12">
                    <span class="txGoldNew"> Slipapge tolerance </span>
                    <span class="text-danger float-right"> 0.5% </span>
                  </div>
                  <div class="col-12">
                    <span class="d-block mx-auto cgb green-button st-shadow-green my-4">
                      Swap
                    </span>
                    <div class="mx-auto" style="max-width: 310px;">
              <p class="mb-1 "><span class="txGoldNew">Minimum Received  </span><span class="float-right text-digit "> 55.0000 GOLDX </span> </p>
              <p class="mb-1 "><span class="txGoldNew">Slippage tolerance </span> <span class="float-right text-digit text-success">   &lt;0.1%  </span> </p>
              <p class="mb-1 "><span class="txGoldNew">Slippage tolerance </span> <span class="float-right text-digit "> 0.000333 BNB </span> </p>
                    
                    </div>
                  </div>
                </div>
              

            </div>
          </div>
          <div class="col-lg-6 col-md-12 px-lg-5">
            <div class="bg-light mx-2 px-4 pt-4 my-2 cornered st-shadow">
              <p class="mb-5 txGoldNew">CONVERT GOLDX TO GOLDX VIP </p>
              <p class="mb-1">GOLDX <span class="float-right text-digit txGoldNew">Balance 0.0000 </span> </p>
              <div class="number-input-container">
                <div class="number-input" style="font-size: 98%;
    line-height: 22px;" placeholder="#777"> 
                  <span class="d-block text-right">0.1234</span>
                  <span class="text-right d-block txGoldNew" style="font-size: 74%;"> ~55.05 USD </span>
                  <div class="float-right">
                    <button class="btn btn-sm g-round">25%</button>
                    <button class="btn btn-sm g-round">50%</button>
                    <button class="btn btn-sm g-round">75%</button>
                  </div>
                </div>
              </div>
              
              <div>
                <span class="d-block mx-auto arrow-cover">
                <img src="../assets/images/1x/arrow-d.png" class="d-block mx-auto" alt="" srcset="" style="max-width:43%;">
              </span>
              </div>
              <p class="mb-1">GOLDX VIP<span class="float-right text-digit txGoldNew">Balance 0.0000 </span> </p>

              <div class="number-input-container">
                <div class="number-input" style="font-size: 98%;
    line-height: 22px;" placeholder="#777"> 
                  <span class="d-block text-right">0.1234</span>
                  <span class="text-right d-block txGoldNew" style="font-size: 74%;"> ~55.05 USD </span>
                  <div class="float-right">
                    <button class="btn btn-sm g-round">25%</button>
                    <button class="btn btn-sm g-round">50%</button>
                    <button class="btn btn-sm g-round">75%</button>
                  </div>
                </div>
              </div>
                <div class="row">
                  <div class="col-lg-7 col-md-12">
                    <span class="txGoldNew"> Price </span>
                    <span class="float-right"> 0.33456 GOLDX VIP per GOLDX </span>
                  </div>
                  <div class="col-lg-5 col-md-12">
                    <span class="txGoldNew"> Slippage tolerance </span>
                    <span class="text-danger float-right"> 0.5% </span>
                  </div>
                  <div class="col-12">
                    <span class="d-block mx-auto cgb green-button st-shadow-green my-4">
                      Swap
                    </span>
                    <div class="mx-auto" style="max-width: 310px;">
              <p class="mb-1 "><span class="txGoldNew">Minimum Received  </span><span class="float-right text-digit "> 55.0000 GOLDX VIP </span> </p>
              <p class="mb-1 "><span class="txGoldNew">Slippage tolerance </span> <span class="float-right text-digit text-success">   &lt;0.1%  </span> </p>
              <p class="mb-1 "><span class="txGoldNew">Slippage tolerance </span> <span class="float-right text-digit "> 0.000333 BNB </span> </p>
                    
                    </div>
                  </div>
                </div>
              

            </div>
          </div>
          
          
        </div>
      </div>
      <div id="sec-ffive">
        <leaderboard />
      </div>
      <div id="sec-ffive">
        <leaderboard-promo />
      </div>
      <div id="sec-six" class="bg-light-extra px-lg-5 mt-5">
        <div class="row m-0 p-0">
          <div class="col-lg-6 col-md-12 px-lg-5 ">
            <div class="bg-light mx-2 mt-2 pb-5 cornered st-shadow stats-fonts p-stats-container">
              <p class="mb-4 txGoldNew">GOLDX GLOBAL STATS </p>
              <p class="mb-1">GOLDX PRICE <span class="float-right text-digit txGoldNew"> $ 0.0000 </span> </p>
              <!-- <p class="mb-1">TOTAL REFERRALS GOLDX <span class="float-right text-digit txGoldNew"> 0.0000 GOLDX </span> </p> -->
              <p class="mb-1">TOTAL GOLDX MINED 
                <span class="float-right text-digit txGoldNew" v-if="GTOTAL_GOLDX_REWARDED"> {{ standardNumber(GTOTAL_GOLDX_REWARDED) }} GOLDX </span>
                <span class="float-right text-digit txGoldNew" v-else> 0.0000 GOLDX </span>
               </p>
              <!-- <p class="mb-1">TOTAL GOLDX REWARDED TODAY 
                <span class="float-right text-digit txGoldNew" v-if="GTOTAL_GOLDX_REWARDED_TODAY"> {{(GTOTAL_GOLDX_REWARDED_TODAY / 10**GOLDX_DECIMALS).toFixed(4) }} GOLDX </span>
                <span class="float-right text-digit txGoldNew" v-else> 0.0000 GOLDX </span>
               </p> -->
              <p class="mb-1">TOTAL GOLDX IN GROUND <span class="float-right text-digit txGoldNew">0.0000 GOLDX </span> </p>

        <!-- <p class="mb-1"><span class="cg">GOLDX In Ground</span> <span class="float-right text-digit txGoldNew cgb"> 0.0000 GOLDX </span> </p> -->

              <!-- <p class="mb-1">TOTAL GOLDX REWARDED BY REFERRALS <span class="float-right text-digit txGoldNew"> 0.0000 GOLDX </span> </p> -->
              <p class="mb-1">TOTAL GOLDX CIRCULATING 
                <span class="float-right text-digit txGoldNew" v-if="totalSupply"> {{ standardNumber(totalSupply) }} GOLDX </span>
                <span class="float-right text-digit txGoldNew" v-else> 0.0000 GOLDX </span>
               </p>
              <p class="mb-1">TOTAL GOLDX EXCHANGED TO FUSEG <span class="float-right text-digit txGoldNew"> 0.0000 GOLDX </span> </p>
              <p class="mb-1">TOTAL GOLDX HOLDERS <span class="float-right text-digit txGoldNew"> 00000 </span> </p>
              <!-- <p class="mb-1">TOTAL GOLDX EXCHANGED TO FUSEG <span class="float-right text-digit txGoldNew"> 0.0000 GOLDX </span> </p> -->
        <!-- <p class="mb-1"><span class="cg">TOTAL GOLDX VIP EXCHANGED TO FUSEG</span> <span class="float-right text-digit txGoldNew cgb"> 0.0000 GOLDX </span> </p> -->

              <!-- <p class="mb-1">TOTAL GOLDS EXCHANGED TO NFTs <span class="float-right text-digit txGoldNew"> 0.0000 GOLDX </span> </p> -->
              <!-- <p class="mb-5">TOTAL GOLDX REMAINING TO BE REWARDED 
                <span class="float-right text-digit txGoldNew" v-if="GTOTAL_GOLDX_REMAINING_TO_BE_REWARDED"> {{ standardNumber(GTOTAL_GOLDX_REMAINING_TO_BE_REWARDED) }} GOLDX </span>
                <span class="float-right text-digit txGoldNew" v-else> 0.0000 GOLDX </span>
               </p> -->

            </div>
          </div>
          <div class="col-lg-6 col-md-12 px-lg-5">
            <div class="bg-light mx-2 my-2 cornered st-shadow stats-fonts p-stats-container">
              <p class="mb-4 txGoldNew">GOLDX Leaderboard STATS </p>
              <p class="mb-1">TOTAL GOLDX HOLDERS <span class="float-right text-digit txGoldNew"> {{ standardNumber(holders) }} </span> </p>
              <p class="mb-1">TOTAL REFERRAL GOLDX <span class="float-right text-digit txGoldNew"> 0.0000 GOLDX </span> </p>
              <p class="mb-1">TOTAL GOLDX REWARDED FROM PLATFORM BUYS 
                <span class="float-right text-digit txGoldNew" v-if="STOTAL_GOLDX_REWARDED_FROM_PLATFORM_BUYS"> {{(STOTAL_GOLDX_REWARDED_FROM_PLATFORM_BUYS / 10**GOLDX_DECIMALS).toFixed(4) }} GOLDX </span> 
                <span class="float-right text-digit txGoldNew" v-else> 0.0000 GOLDX </span> 
              </p>
              <p class="mb-1">TOTAL GOLDX REWARDED FROM PLATFORM SELLS 
                <span class="float-right text-digit txGoldNew" v-if="STOTAL_GOLDX_REWARDED_FROM_PLATFORM_SELLS"> {{(STOTAL_GOLDX_REWARDED_FROM_PLATFORM_SELLS / 10**GOLDX_DECIMALS).toFixed(4) }} GOLDX </span> 
                <span class="float-right text-digit txGoldNew" v-else> 0.0000 GOLDX </span> 
              </p>
              <p class="mb-1">TOTAL GOLDX REWARDED FROM PLATFORM EXCHANGE 
                <span class="float-right text-digit txGoldNew" v-if="STOTAL_GOLDX_REWARDED_FROM_PLATFORM_EXCHANGE"> {{(STOTAL_GOLDX_REWARDED_FROM_PLATFORM_EXCHANGE / 10**GOLDX_DECIMALS).toFixed(4) }} GOLDX </span>
                <span class="float-right text-digit txGoldNew" v-else> 0.0000 GOLDX </span>
               </p>
              <p class="mb-1">TOTAL GOLDX REWARDED FROM DEX BUYS 
                <span class="float-right text-digit txGoldNew" v-if="STOTAL_GOLDX_REWARDED_FROM_DEX_BUYS"> {{ standardNumber(STOTAL_GOLDX_REWARDED_FROM_DEX_BUYS) }} GOLDX </span>
                <span class="float-right text-digit txGoldNew" v-else> 0.0000 GOLDX </span>
               </p>
              <p class="mb-1">TOTAL GOLDX REWARDED FROM DEX SELLS 
                <span class="float-right text-digit txGoldNew" v-if="STOTAL_GOLDX_REWARDED_FROM_DEX_SELLS"> {{ standardNumber(STOTAL_GOLDX_REWARDED_FROM_DEX_SELLS) }} GOLDX </span>
                <span class="float-right text-digit txGoldNew" v-else> 0.0000 GOLDX </span>
               </p>
              <p class="mb-1">TOTAL GOLDX EXCHANGED TO FUSEG <span class="float-right text-digit txGoldNew"> 0.0000 GOLDX </span> </p>
              <p class="mb-5">TOTAL GOLDX EXCHANGED TO NFTs <span class="float-right text-digit txGoldNew"> 0.0000 GOLDX </span> </p>

            </div>
          </div>
          <div class="col-lg-6 p-0 m-0">
            <div class="bg-light mr-lg-4 pt-4 my-2 cornered st-shadow stats-fonts p-stats-container">
              <!-- <p class="mb-3 txGoldNew cgb">GOLDX STATS </p> -->
  <b-button v-b-toggle.collapse-2 variant="txGoldNew">GOLDX VIP STATS  &#9660;</b-button>
   
    <b-collapse  id="collapse-2" >
        <p class="mb-1">GOLDX VIP PRICE <span class="float-right text-digit txGoldNew"> $ 0.0000 </span> </p>
        <p class="mb-1"><span class="cg">TOTAL GOLDX VIP REWARDED</span> <span class="float-right text-digit txGoldNew cgb"> 0.0000 GOLDX </span> </p>
        <p class="mb-1"><span class="cg">TOTAL GOLDX VIP REMAINING TO BE REWARDED</span> <span class="float-right text-digit txGoldNew cgb"> 0.0000 GOLDX </span> </p>
        <p class="mb-1"><span class="cg">TOTAL GOLDX VIP IN GROUND</span> <span class="float-right text-digit txGoldNew cgb"> 0.0000 GOLDX </span> </p>
        <p class="mb-1"><span class="cg">TOTAL GOLDX VIP In CIRCULATING</span> <span class="float-right text-digit txGoldNew cgb"> 0.0000 GOLDX </span> </p>
        <!-- <p class="mb-1"><span class="cg">TOTAL GOLDX VIP REMAINING TO BE REWARDED</span> <span class="float-right text-digit txGoldNew cgb"> 0.0000 GOLDX </span> </p> -->
        <p class="mb-1"><span class="cg">TOTAL GOLDX VIP BURNED</span> <span class="float-right text-digit txGoldNew cgb"> 0.0000 GOLDX </span> </p>
        <!-- <p class="mb-1"><span class="cg">TOTAL GOLDX VIP REWARDED</span> <span class="float-right text-digit txGoldNew cgb"> 0.0000 GOLDX </span> </p> -->
        <!-- <p class="mb-1"><span class="cg">TOTAL GOLDX VIP REWARDED TODAY </span> <span class="float-right text-digit txGoldNew cgb"> 0.0000 GOLDX </span> </p> -->
        <p class="mb-1"><span class="cg">TOTAL GOLDX VIP EXCHANGED TO FUSEG</span> <span class="float-right text-digit txGoldNew cgb"> 0.0000 GOLDX </span> </p>
        <p class="mb-1"><span class="cg">TOTAL GOLDX VIP HOLDERS</span> <span class="float-right text-digit txGoldNew cgb"> 00000 </span> </p>
    </b-collapse>
  
              
              <!-- <img src="../assets/images/1x/claim.png" class="d-block mx-auto my-4" alt="" srcset="" style="max-width:55%;cursor: pointer;"> -->

            </div>
          </div>
          
        </div>
      </div>
    <footer-section></footer-section>

  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue"
import VueApexCharts from 'vue-apexcharts'
import NftComp from "@/components/NftComp.vue"
import FooterSection from "@/components/FooterSection.vue"
import BuyContainer from "@/components/BuyContainer.vue"
import Leaderboard from "@/components/Leaderboard.vue"
import LeaderboardPromo from "@/components/LeaderboardPromo.vue"
import millify from "millify";
import { mapState } from 'vuex'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: "Mint",
  components:{
  'apexchart':VueApexCharts,
    NavBar, FooterSection, NftComp, BuyContainer, Leaderboard,
    LeaderboardPromo, 
    VueSlickCarousel
  },
 methods:{
  
  async getPrevRewards(){
    let data = await this.$store.dispatch("getDailyRewards")
    this.dailyRewards = data
  },
  async getApprovedNFTSData(){
    let data = await this.$store.dispatch("getApprovedNFTSData");
    this.approvedNFTSData = data.combinedEntries
    this.allVerifiedByRounds()

  },
  allVerifiedByRounds(){ 
    this.$store.state.nftbyround = {}
      this.rounds = {"1" : {miners:0, prospectors:0, power:0, refiners: 0}};
      let current = "1";
      this.approvedNFTSData.forEach(element => {
        this.PP += Number(element.power) 
        let pw = Number(element.power);
        let m = Number(element.m)
        let p = Number(element.p)
        if(m){
          this.rounds[current].miners += m;
          this.rounds[current].power += pw;
        }else if(p){
          this.rounds[current].prospectors += p;
          this.rounds[current].power += pw;
        }else{
          this.rounds[current].refiners ++;
          this.rounds[current].power += 3.8;          
        }
        this.$store.state.nftbyround[element.id] = current
        if(this.rounds[current].power > (99.9999)){
          console.log("round cleared")
          let n = Number(current);
          n++;
          current = n.toString();
          this.rounds[current] = {miners:0, prospectors:0, power:0, refiners: 0}
        }
      });
      return this.rounds;
     },
  comma(number) {
    let numberStr = number.toString();
    return numberStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
},
  async shareTweet(status) {
    const tweetContent = encodeURIComponent(`I just created a GOLDX NFT and added Mining Rights. You can too! \n ${(this.selectedInvite.data.url) ? this.selectedInvite.data.url : ""} \n #fusegold #GOLDX #GOLDXNFT #FORTG \n `);
    const mediaUrl = encodeURIComponent("https://goldx.io");
    const twitterUrl = `https://twitter.com/intent/tweet?text=${tweetContent}&url=${mediaUrl}`;
    window.open(twitterUrl,"_blank")
    this.$store.dispatch("updateInvite",{invite: this.selectedInvite, status})
    this.$bvModal.hide("modal-tweet")
    console.log(status)
    
    },
  updateInvite(status){
    console.log(status)
   if(status !== null){
    this.$store.dispatch("updateInvite",{invite: this.selectedInvite, status})
   }
  },
  proceedInvite(){
    this.selectedInvite = this.userInvites[0];
    this.$bvModal.show("modal-tweet")

    //openModal
  },
  searchStats(){
    this.$store.dispatch("searchStats",{ids:this.IDS,provider: this.$store.state.provider})
  },
  async requestWrap(){
    let length = 0
    length += this.WRPmins.length
    length += this.WRPpros.length
    let error = ""
    if(length < 2) error = "Minimum 2 IDs required"
    if(this.WRPid.length) error = "Target NFT ID Required"
    if(!error.length){
      // let miners =  
      // let ids = this.WRPmins
      // let dd = JSON.parse(JSON.stringify(this.WRPpros) )
      // ids.push(...dd)
      // console.log(ids)
      this.allIds = [...this.WRPmins, ...this.WRPpros];

      let request = { from: this.userWallet, for:"Combine", miners: this.WRPmins,ids: this.allIds, pros: this.WRPpros, target: this.WRPid}
      let dec = await this.$store.dispatch("requestWrap",{request})
      if(dec.status){
        alert("request submitted")
      }else{
        alert("error in submitting request")
      }
      // console.log(request)
      
    }
    //is wallet connected
    //is there more then 1 IDs in request
    //is target NFT ID selected


    //proceed request 
  },
  handleTouchStart(event) {
      this.touchStartX = event.touches[0].clientX;
      this.touchMoveX = this.touchStartX;
      this.isSwiping = true;
    },
    handleTouchMove(event) {
      if (this.isSwiping) {
        this.touchMoveX = event.touches[0].clientX;
        const deltaX = this.touchMoveX - this.touchStartX;
        this.$refs.scrollContainer.scrollLeft -= deltaX;
      }
    },
    handleTouchEnd() {
      this.isSwiping = false;
    },
  OK(){
    localStorage.setItem("disclaimer","true")
    this.$bvModal.hide("modal-disc")
  },
  scrollLeft() {
    console.log("left")
      if (!this.isAtMinScrollPosition) {
        this.scrollPosition -= this.scrollAmount;
        this.updateScroll();
      }
    },
    scrollRight() {
      console.log("right")
      if (!this.isAtMaxScrollPosition) {
        this.scrollPosition += this.scrollAmount;
        this.updateScroll();
      }
    },
    updateScroll() {
      const content = this.$refs.content;
      content.style.transform = `translateX(${-this.scrollPosition}px)`;
    },
    async claimSingle(id){
      this.claiming = true
      await this.$store.dispatch("claimRewards", {id, wallet:this.userWallet})
      this.claiming = false
    },
  // getCalimIDS(){}
  async claimProspectors(){
    let ids = [];
    if(this.availRewardsPros > 1){
      ids = this.getCalimIDS
    this.claiming = true
    let dec = await this.$store.dispatch("claimBatch", {ids, wallet: this.userWallet})
    if(dec.status){
    this.claiming = false
    }
    }
  },
  async claimCustom(){
    let ids = [];
    if(this.availRewardsCustom > 1){
      ids = this.getCalimCustomIDS
      this.claiming = true
      // console.log(ids)
      let dec = await this.$store.dispatch("claimBatch", {ids, wallet: this.userWallet})
      if(dec.status){
        this.claiming = false
      }
    }
  },
  async claimMiners(){
    let ids = [];
               
    ids = this.MIDS
    this.CMs = true
    await this.$store.dispatch("claimBatch", {ids, wallet: this.userWallet})
    this.CMs = false
  },
  
  async claimAll(){
    let ids = [];
    ids = this.getCalimIDS
    this.CPs = true
    let dec = await this.$store.dispatch("claimBatch", {ids, wallet: this.userWallet})
    this.CPs = false
    if(dec.status){
      //refetch all NFTs
      // await this.$store.dispatch("loadNFTs",{wallet: this.userWallet, provider: this.$store.state.provider})
    }
  },
  async submit(){
    console.log(this.slClass, this.slQty)
    if(this.slClass == null){
      alert("Class is required, action cancelled")
    }else{
      let request = {
        from:this.userWallet,
        for:this.slClass,
        ids:(this.slClass == 'Custom') ? this.slID : this.slQty,
        USD:(this.slClass == 'Custom') ? this.slUSD : 0 ,
        power:(this.slClass == 'Custom') ? Number((this.slUSD * this.PDP).toFixed(5)) : 0
      }
      console.log("request is", request)

          let dec = await this.$store.dispatch("submitRequest", {request})
      if(dec.status){
        this.$store.dispatch("getRequests",{wallet: this.userWallet})
      this.$store.dispatch("getRequestsCompleted",{wallet: this.userWallet})
        this.slQty = [];
        this.slClass = null
         alert(("request submitted"))
      }else{
        alert(("Failed to submit request"))
      }
        // }

      // }else{
        // let message = "You Don't Have the Purchase Rights for "+this.slClass+' Class, please purchase first or check if you are using the correct wallet.'
        // alert(message)
      // }
      
    }
  },

  removeDuplicatesById(arr) {
    const uniqueMap = new Map();
  
  for (const obj of arr) {
    if (!uniqueMap.has(obj.id)) {
      uniqueMap.set(obj.id, obj);
    }
  }
  
  return Array.from(uniqueMap.values());

},
  standardNumber: function(value){
      if (!value) return ''
      return millify((value/10**this.GOLDX_DECIMALS) , { precision: 3 }
      ); 
    }
 },
 data(){
  return {
    dailyRewards:[],
    PP:0,
    selectedInvite:null,
    inviteAsked:false,
    WRPid:null,
    WRPmins:[],
    WRPpros:[],
    allIds:[],
    
    IDS:[],
    error:"",
    disc:false,
    touchStartX: 0,
      touchMoveX: 0,
      isSwiping: false,
    scrollPosition: 0,
      scrollAmount: 250,
    
      circleReferrals:{
      fill: {
              type: 'gradient',
              gradient: {
                shade: 'dark',
                type: 'horizontal',
                shadeIntensity: 0.5,
                gradientToColors: ['#E92EFB'],
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100]
              }
            },
    chart: {
            height: 280,
          type: "radialBar"
        },
        
        series: [67],
        
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 15,
              size: "60%"
            },
           
            dataLabels: {
              showOn: "always",
              name: {
                offsetY: -10,
                show: false,
                color: "#888",
                fontSize: "13px"
              },
              value: {
                color: "#b3b3b3",
                fontSize: "30px",
                show: true
              }
            }
          }
        },
      
        stroke: {
          lineCap: "round",
        },
      },
      circleReferralsM:{
      fill: {
              type: 'gradient',
              gradient: {
                shade: 'dark',
                type: 'horizontal',
                shadeIntensity: 0.5,
                gradientToColors: ['#E92EFB'],
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100]
              }
            },
    chart: {
            height: 280,
          type: "radialBar"
        },
        
        series: [67],
        
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 15,
              size: "60%"
            },
           
            dataLabels: {
              showOn: "always",
              name: {
                offsetY: -10,
                show: false,
                color: "#888",
                fontSize: "13px"
              },
              value: {
                color: "#b3b3b3",
                fontSize: "30px",
                show: true
              }
            }
          }
        },
      
        stroke: {
          lineCap: "round",
        },
      },
      circleReferralsMS:{
      fill: {
              type: 'gradient',
              gradient: {
                shade: 'dark',
                type: 'horizontal',
                shadeIntensity: 0.5,
                gradientToColors: ['#E92EFB'],
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100]
              }
            },
    chart: {
            height: 280,
          type: "radialBar"
        },
        
        series: [67],
        labels:["Miners"],

        
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 15,
              size: "60%"
            },
           
            dataLabels: {
              showOn: "always",
              name: {
                offsetY: 0,
                show: true,
                color: "#888",
                fontSize: "15px"
              },
              value: {
                offsetY: 0,
                color: "#b3b3b3",
                fontSize: "15px",
                show: true
              }
            }
          }
        },
      
        stroke: {
          lineCap: "round",
        },
      },
      circleReferralsPS:{
      fill: {
              type: 'gradient',
              gradient: {
                shade: 'dark',
                type: 'horizontal',
                shadeIntensity: 0.5,
                gradientToColors: ['#E92EFB'],
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100]
              }
            },
    chart: {
            height: 280,
          type: "radialBar"
        },
        
        series: [67],
        labels:["Prospectors"],

        
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 15,
              size: "60%"
            },
           
            dataLabels: {
              showOn: "always",
              name: {
                offsetY: 0,
                show: true,
                color: "#888",
                fontSize: "13px"
              },
              value: {
                offsetY: 0,
                color: "#b3b3b3",
                fontSize: "15px",
                show: true
              }
            }
          }
        },
      
        stroke: {
          lineCap: "round",
        },
      },
    settings:{
  "dots": true,
  "arrows": true,
  "infinite": false,
  "speed": 500,
  "slidesToShow": 4,
  "slidesToScroll": 4,
  "initialSlide": 0,
  "responsive": [
    {
      "breakpoint": 1024,
      "settings": {
        "slidesToShow": 3,
        "slidesToScroll": 3,
        "infinite": true,
  "arrows": true,
        "dots": true
      }
    },
    {
      "breakpoint": 600,
      "settings": {
        "slidesToShow": 2,
        "slidesToScroll": 2,
        "initialSlide": 2
      }
    },
    {
      "breakpoint": 480,
      "settings": {
        "slidesToShow": 1,
        "slidesToScroll": 1
      }
    }
  ]
},
    SLcls:null,
    value: [false, false, false],
      collapses: [
        { title: "First collapse", content: "First collapse content" },
        { title: "Second collapse", content: "Second collapse content" },
        { title: "Third collapse", content: "Third collapse content" },
      ],
    SLNFT:null,
    showBatchMRR:false,
    CMs: false,
    CPs: false,
    slUSD:5,slID:"",
    claiming: false,
    nft:[1,2,3,4],
    classes:["Refiners",'Miners','Prospectors','Custom'],
    slClass: null,slQty:[],
    approvedNFTSData:[],
    rounds:{},
    // nftbyround:{},

  }
 },
 mounted(){
  // this.generateToken();
  setTimeout(async () => {
    await this.$store.dispatch("setToken")
    // console.log(token, this.$store.state.TOKEN)
    this.getApprovedNFTSData()
  this.getPrevRewards()
  this.$store.dispatch("loadCounters")        

  setInterval(() => {
    if(this.userWallet){
      // console.log("wallet is connected, checking invites");
      if(this.userInvites.length && this.inviteAsked == false){
        // console.log("user has invites and he is not asked yet");
        this.inviteAsked = true
        this.proceedInvite()
      }
    }
  }, 10000);
  let desc = localStorage.getItem("disclaimer");
  if(desc){
    this.disc = false
  }else{
    this.$bvModal.show("modal-disc")
  }
  if(this.$route.name == "miningrights"){
    // console.log("procedd purchase form")
    this.$bvModal.show("modal-xl")
    
  }
  // console.log("params are",this.$route)
  this.$store.dispatch("pullPurchases")
  this.circleReferrals.plotOptions.radialBar.size = 55
  setInterval(() => {
    this.settings.arrows = true;
    this.settings.responsive[0].arrows = true;    
  }, 1000);

  }, 500);
 },
  computed: mapState({
    totalSales: state => state.totalSales,
    pendingNFTs: state => state.pendingNFTs,
    NFTs: state => state.NFTs,
    NFTLOADING: state => state.NFTLOADING,
    GOLDX_USERS: state => state.GOLDX_USERS,
    userWallet: state => state.userWallet,
    GOLDX_DECIMALS: state => state.GOLDX_DECIMALS,
    STOTAL_GOLDX_REWARDED_FROM_PLATFORM_BUYS: state => state.STOTAL_GOLDX_REWARDED_FROM_PLATFORM_BUYS,
    STOTAL_GOLDX_REWARDED_FROM_PLATFORM_SELLS: state => state.STOTAL_GOLDX_REWARDED_FROM_PLATFORM_SELLS,
    STOTAL_GOLDX_REWARDED_FROM_PLATFORM_EXCHANGE: state => state.STOTAL_GOLDX_REWARDED_FROM_PLATFORM_EXCHANGE,
    GTOTAL_GOLDX_REMAINING_TO_BE_REWARDED: state => state.GTOTAL_GOLDX_REMAINING_TO_BE_REWARDED,
    totalSupply: state => state.totalSupply,
    GTOTAL_GOLDX_REWARDED_TODAY: state => state.GTOTAL_GOLDX_REWARDED_TODAY, 
    GTOTAL_GOLDX_REWARDED: state => state.GTOTAL_GOLDX_REWARDED,
    PFUSEG_BALANCE: state => state.PFUSEG_BALANCE,
    PGOLDX_BALANCE: state => state.PGOLDX_BALANCE,
    FUSEG_PRICE: state => state.FUSEG_PRICE,
    activities: state => state.activities,
    STOTAL_GOLDX_REWARDED_FROM_DEX_SELLS: state => state.STOTAL_GOLDX_REWARDED_FROM_DEX_SELLS,
    STOTAL_GOLDX_REWARDED_FROM_DEX_BUYS: state => state.STOTAL_GOLDX_REWARDED_FROM_DEX_BUYS,
    PTOTAL_GOLDX_REWARDED_FROM_DEX_SELLS: state => state.PTOTAL_GOLDX_REWARDED_FROM_DEX_SELLS,
    PTOTAL_GOLDX_REWARDED_FROM_DEX_BUYS: state => state.PTOTAL_GOLDX_REWARDED_FROM_DEX_BUYS,
    appRef(){ return this.$store.state.appRef },
    appMin(){ return this.$store.state.appMin },
    appPros(){ return this.$store.state.appPros },
    pendRef(){ return this.$store.state.pendRef },
    pendMin(){ return this.$store.state.pendMin },
    pendPros(){ return this.$store.state.pendPros },
    myRewards(){ return this.$store.state.myRewards.slice().reverse(); },
    allRewardsGrouped(){ return this.$store.state.allRewardsGrouped; },
    totalPros(){ return this.$store.state.totalPros },
    totalMiners(){ return this.$store.state.totalMiners },
    refTotal(){ return this.$store.state.refTotal },
    refSold(){ return this.$store.state.refSold },
    soldPros(){ return this.$store.state.soldPros },
    soldMiners(){ return this.$store.state.soldMiners },
    userInvites(){ return this.$store.state.userInvites },
    nftbyround(){return this.$store.state.nftbyround},
    holders(){
      return 3563431
    },
    myEstimatedRwards(){
      return 0
    },
    ALLSOLDPOWER(){
let pw = 0;
      this.approvedNFTSData.forEach(element => {
        pw += Number(element.power)
      });
      return pw
    },
    isAtMinScrollPosition() {
      return this.scrollPosition <= 0;
    },
    isAtMaxScrollPosition() {
      const content = this.$refs.content;
      const viewportWidth = content.parentElement.offsetWidth;
      const contentWidth = content.scrollWidth;
      return this.scrollPosition + viewportWidth >= contentWidth;
    },
    approvedNFTs(){
      return this.$store.state.approvedNFTs
    },
    allVerified(){ return this.$store.state.verified },
    
    allVerifiedUSD(){ 
      // let USD = 0;
      // this.allVerified.forEach(element => {
      //   USD += Number(element.priceUSD)
      // });
      let pros = Number(this.$store.state.soldPros) * 5;
      let miners = Number(this.$store.state.soldMiners) * 100;
      let refiners = 5 * 25000;
      return (pros + miners + refiners);
     },
     allVerifiedMIN(){ 
      let USD = 0;
      this.allVerified.forEach(element => {
        if(element.type == "Miners") USD++
      });
      return USD;
     },
     allVerifiedPROS(){ 
      let USD = 0;
      this.allVerified.forEach(element => {
        if(element.type == "Prospectors") USD++
      });
      return USD;
     },
     TUSD(){
      let one = 5 * 25000;
      let two = Number(this.totalPros) * 5;
      let three = Number(this.totalMiners) * 100;
      return (one + two + three)
     },
     GLOBALSOLDPOWER(){
      let one = Number(this.refSold) * 0.1888;
      let two = Number(this.soldPros) * 0.0000045;
      let three = Number(this.soldMiners) * 0.00009013;
      return (three + two + one)
     },
     PROMOPOWER(){
      let total = 2.5
      let sold =  (14.64 + total) - this.GLOBALSOLDPOWER
      let perc = (sold / total) * 100;
      return {total, sold, perc}
     },
     round() {
      const step = 1.25;
      return Math.floor(this.GLOBALSOLDPOWER / step) + 1;
    },
     remainUSD(){
      return Number(this.TUSD - this.allVerifiedUSD)
     },
     PDP(){
      return Number(0.00036 / 5)
     },
     totalPower(){
      let one = 1* 19 
      let two = 5625* 0.036
      let three = 112500 * 0.0018
      return (Number(one) + Number(two) + Number(three) )

     },
     
     
     NSTATS(){
      return this.$store.state.NFTSTATS
     },
    
    verifiedGrouped(){
          const groupedData = {};
          this.$store.state.verified.forEach(item => {
            const { wallet, type, qty, ...rest } = item;

if (!groupedData[wallet]) {
  groupedData[wallet] = {};
}

if (!groupedData[wallet][type]) {
  groupedData[wallet][type] = {
    items: [],
    totalQty: 0
  };
}

groupedData[wallet][type].items.push({ ...rest });
groupedData[wallet][type].totalQty += Number(qty);
})
return groupedData
    },
  mRights(){
    let wallet = this.userWallet;
    let rights = (this.verifiedGrouped[wallet] !== undefined) ? this.verifiedGrouped[wallet] : {};
    return rights
  },  
    pendNFTs: (state) => {
      let counter = 0;
      state.pendingNFTs.forEach(element => {
        counter += element.ids.length
      });
      return counter;
    },
    pendNFTsIDs: (state) => {
      let ids = [];
      state.pendingNFTs.forEach(element => {
        element.ids.forEach(elm => {
          ids.push(elm)
        });
      });
      return ids;
    },
    
    NFTss(){
      const uniqueArray = this.removeDuplicatesById(this.NFTs);
      return uniqueArray;
      // return this.NFTs
    },
    NFTssSep(){
      let count = {pros:[], min:[]};
      this.NFTss.forEach(element => {
        if(element.mineable && element.stats !== null){
          if(!element.stats[1]){
            if(element.stats[0][2] == "2")
            {
              count.min.push(element)
            }
            if(element.stats[0][2] == "3")
            {
              count.pros.push(element)
            }
          }
        }
      });
      return count;
    },
    PSTATS(){
      if(this.NFTss.length){
        let counter = {
          ref:0,
          min:0,
          pros:0,
        };
        this.NFTss.forEach(element => {
          if(element.mineable){
            if(element.stats[0][2] == "2") counter.min++
            if(element.stats[0][2] == "3") counter.pros++
          }
        });
return counter;
      }else{
        return {
          ref:0,
          min:0,
          pros:0,
        }
      }
    },
    allPower(){
      let power = 0;
      this.NFTss.forEach(element => {
        if(element.mineable && element.stats !== null){
          if(element.stats[1]){
            if(element.stats[3] == "0"){
            // this one has prospector power
            power += (Number(element.stats[4]) * 0.00036)
            }
            if(element.stats[4] == "0"){
              power += (Number(element.stats[3]) * 0.0072)
            // this one has Miner power
            }
            //this is custom
          }else{
            if(element.stats[0][2] == "2"){
              power +=  0.0072
            }else if(element.stats[0][2] == "1"){
              power +=  3.8
            }
            else{
              power +=  0.00036
            }
          }
        }
      });
      return power;
    },
    allPowerGlobal(){
      let power = 0;
      this.NFTss.forEach(element => {
        if(element.mineable && element.stats !== null){
          if(element.stats[1]){
            if(element.stats[3] == "0"){
            // this one has prospector power
            power += (Number(element.stats[4]) * 0.0000045)
            }
            if(element.stats[4] == "0"){
              power += (Number(element.stats[3]) * 0.00009013)
            // this one has Miner power
            }
            //this is custom
          }else{
            if(element.stats[0][2] == "2"){
              power +=  0.00009013
            }else{
              power +=  0.0000045

            }
          }
        }
      });
      return power;
    },
    

    availRewardsMiners(){
      let rewards = 0
      this.NFTss.forEach(element => {
        if(element.mineable){
          let rw = Number(element.rewards)
          if(rw > 0 && element.stats[0][2] == "2")
          {
            if(!element.stats[1]){
              rewards += ( Number(element.rewards) )
            }
          }
        }
      });
      return Number((rewards/10**this.GOLDX_DECIMALS).toFixed(2))
    },
    availRewardsPros(){
      let rewards = 0
      this.NFTss.forEach(element => {
        if(element.mineable){
          let rw = Number(element.rewards)
          if(rw && element.stats[0][2] == "3")
          {
            if(!element.stats[1]){
              rewards += ( Number(element.rewards) )
            }
          }
        }
      });
      return Number((rewards/10**this.GOLDX_DECIMALS).toFixed(2))
    },
    availRewardsCustom(){
      let rewards = []
      this.NFTss.forEach(element => {
        if(element.mineable){
          if(element.stats[1]){
            // let rw = Number(element.rewards)
            rewards.push(element)
          }
        }
      });
      return rewards
    },
    availRewards(){
      let rewards = 0;
      // rewards += Number(this.availRewardsMiners)
      // rewards += Number(this.availRewardsPros)
      // this.availRewardsCustom.forEach(element => {
      //   if(element.rewards !== 0 && element.rewards !== '0'){
      //     rewards += Number( Number(element.rewards) / 10**this.GOLDX_DECIMALS )
      //   }
      // });
      rewards = (this.$store.state.PMRewards / this.PP) * ( Number(this.allPower) / 100)
      return rewards
    },
    getCalimIDS(){

      let ids = []
      this.NFTss.forEach(element => {
        if(element.mineable){
          let rw = Number(element.rewards)
          if(rw > 0 && element.stats[0][2] == "3")
          {
            // ids.push(element.id)
            if(!element.stats[1])
          {
            ids.push(element.id)
          }
          }
        }
        
      });
      return ids
    },
    getCalimCustomIDS(){
      let ids = []
      this.NFTss.forEach(element => {
        if(element.mineable){
          let rw = Number(element.rewards)
          if(rw > 0 && element.stats[1])
          {
            ids.push(element.id)
          }
        }
        
      });
      return ids
    },
    MIDS(){
      let ids = []
      this.NFTss.forEach(element => {
        if(element.mineable){
          let rw = Number(element.rewards) - Number(element.stats[1])
        if(rw > 0 && element.stats[0][2] == "2")
        {
          if(!element.stats[1])
          {
            ids.push(element.id)
          }
          // ids.push(element.id)
        }
        }
        
      });
      return ids
    },
    avaiNFTs(){
       let arr = this.NFTss.filter((item) => { return !item.mineable})
      //  let nfts = arr.length;
      //  nfts =  (nfts - this.pendNFTs)
       return arr
    },
    avaiNFTsIDS(){
      let arr = []
      this.avaiNFTs.forEach(element => {
        if(!this.pendNFTsIDs.includes(element.id))
        {
          arr.push(element)
        }
      });
      //  let arr = this.avaiNFTs.filter((item) => { return this.pendingNFTs.includes(item.id) })
      //  let nfts = arr.length;
      //  nfts =  (nfts - this.pendNFTs)
       return arr
    },
    // pendingNFTs
    avaiMinNFTs(){
      let nfts = this.NFTs.filter((item) => { return item.mineable})
       return nfts
    },
    getLastRound() {
  const keys = Object.keys(this.rounds);
  if(keys){
    let l =keys.length;
  l = l.toString()
  return {key:l,data:this.rounds[l]};
  }else{
    return {data:{ power:0}}
  }
  
}
    
    

  })
    
    // ...mapState(["GOLDX_USERS","STOTAL_GOLDX_REWARDED_FROM_PLATFORM_BUYS","STOTAL_GOLDX_REWARDED_FROM_PLATFORM_SELLS","STOTAL_GOLDX_REWARDED_FROM_PLATFORM_EXCHANGE"])
  
}
</script>
<style scoped>
.marquee-container {
  background-color: #f8f9fa;
  padding: 1rem 0;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  width: 100%; /* Ensure full width */
}

.marquee {
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  width: 100%;
  height:66px;
}

.marquee-text {
  font-family: 'Arial', sans-serif;
  font-size: 1.25rem;
  font-weight: bold;
  color: #b99653 !important;
  background-color: transparent;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
  position: absolute;
  left: 0; /* Static position */
}

.marquee span {
  display: inline-block;
  animation: marquee 30s linear infinite;
}

@keyframes marquee {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}


/* .w300gx{
  width:200px;
}
.w300gxTwo{
  width:186px;
}
.w400gx{
  width:350px;
}
.w400gxTwo{
  width:350px;
} */
.w400gx > p, .w300gx > p, .w300gxTwo > p, .cont-layer p {
  font-size:82%;
  font-family:"Calibri";

}
/* .pgoldxTwo{
    padding-left: 24%;
} */
.w17x{
  width:172px;
}
.bgimg{
  background-image: url('../assets/images/Calque 1.png');
  background-position: right;
  background-repeat: no-repeat;
}
@media only screen and (max-width: 1695px) {
  
}
@media only screen and (max-width: 1200px) {
/* .pgoldxTwo{
padding-left:14% !important;
} */
}

@media only screen and (max-width: 440px) {
  
  .w400gx > p, .w300gx > p, .w300gxTwo > p, .cont-layer p {
    font-family:"Calibri";
  font-size:65% !important;
  padding-left:5px;
}

.cont-layer .w400gx{width:280px !important;}
.bgimg .w400gx{width:283px !important;}

.bgimg[data-v-4f15d69c] {
    background-size: contain;
}

}
.cont-layer{
  /* border:1px solid red !important; */
  width:553px;
}
.cont-layer-2{
  width:422px;
}
.cont-layer .w400gx, .bgimg .w400gx{width:350px;}
.claim-button{
  min-width:12vw;
  float: right;
}
.modal-container{
 position: absolute !important;
 z-index: 99999999 !important;
 border:2px solid red !important; 
  display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    
    background-color: #3e3e3e21 !important;
}
.va-card{
  min-height:134px !important;
}

.number-input-container {
      width: 100%;
    display: inline-block;
    height: 7em;
    background-color: white;
    padding: 0;
    border-radius: 5px;
    overflow: hidden;
}

.number-input {
  width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 1em 0.5em;
    border: none;
    font-weight:600;
    background: none;
    text-align: center;
    outline: none;
    font-size: 167%;
    line-height: 26px;
}
.number-input-disabled {
  width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 1em 0.5em;
    border: none;
    font-weight:600;
    color:#FFFFFF;
    background: #b99653;
    text-align: center;
    outline: none;
    font-size: 167%;
    line-height: 26px;
}

.number-input-disabled::placeholder {
  color:#FFFFFF;
  font-family : GOTHICB;
  font-weight : bold;
}
.number-input::placeholder {
  text-align: center;
}
.number-input::-webkit-inner-spin-button,
.number-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.green-button-em{
  max-width: 38%;
    text-align: center;
    background:#FFFF;
    color:  rgb(84 84 84);
    /* box-shadow: 0px 1px 9px 0px #FFF;  */
    box-shadow: 0px 0px 6px 6px rgba(0, 0, 0, 0.1);

    display: inline-block;
    padding: 15px 15px;
    font-weight: 555;
    font-size: 71%;
    cursor: pointer;
    border-radius: 12px;
    position: absolute;
    top: 0px;
    right: 0px;

}

.input-cont-div {
  border-radius: 14px;
    position: relative;
    height: 47px;
}
#nm{
  box-sizing: border-box;
    border: none;
    border-left: 2px solid #b99653;
    font-weight: 600;
    color: #000;
    outline: none;
    margin-top: 8px;
    margin-left: 12px;
    height: 32px;
    font-size: 100%;
    line-height: 30px;
    line-height: 30px;
}
#nm::placeholder {
  color:lightslategray;
}
.slick-next, .slick-prev, .custom-arrow, .slick-arrow{
  /* color: #000 !important; */
}
.slick-prev:before, .slick-next:before { 
    /* color:red !important; */
}
</style>
<style>




.scrollable-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 400px;
}

.scrollable-content {
  white-space: nowrap;
  display: inline-block;
  transition: transform 0.3s;
  /* transform: scale(0.9) */
}

.scroll-button {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 40px;
  background: #FFF;
  /* color: black; */
  font-size: 45px;
  border: none;
  cursor: pointer;
  z-index: 999999;
}

.scroll-button.lefts {
  left: -2px;
}

.scroll-button.rights {
  right: 0;
}
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
// import Sacrifice from '../views/Sacrifice.vue'
import FgAdmin from '../views/FgAdmin.vue'
import Registration from '../views/Registration.vue'

Vue.use(VueRouter)

const routes = [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
  {
    path: '/fg-admin/:username',
    name: 'FgAdmin',
    component: FgAdmin
  },
  {
    path: '/referral/:wallet',
    name: 'referral',
    component: Home
  },
  {
    path: '/miningrights',
    name: 'miningrights',
    component: Home
  },
  {
    path: '/registration',
    name: 'Registration',
    component: Registration
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

<template>
  <div class="">
    <nav-bar></nav-bar>
    <div class="">
        <div class="row justify-content-center">
            <div class="col-md-8">
                
        <p class="text-dark text-center my-5">
            Dear esteemed members of <a href="https://goldx.io" target="_blank" class="txGold" rel="noopener noreferrer">goldx.io</a> and existing members of <a href="https://fuse.gold" target="_blank" class="txGold" rel="noopener noreferrer">fuse.gold</a>,
<br>
We extend our sincere appreciation for your support and participation in our platform. We are pleased to announce that our referral rewards system now offers payouts in both cryptocurrency and gold X VIP reward points. This innovative feature not only enables you to earn daily crypto rewards during the gold X GENESIS round but also presents an exciting opportunity to compete for a top position on the <a href="https://fuse.gold" target="_blank" class="txGold" rel="noopener noreferrer">fuse.gold</a> Leaderboard, which can result in additional gold X rewards.
<br>
To streamline our operations and ensure a seamless experience, we are implementing a new requirement regarding wallet addresses associated with existing <a href="https://fuse.gold" target="_blank" class="txGold" rel="noopener noreferrer">fuse.gold</a> accounts. In order to earn referral rewards and gold X VIP rewards, it will be mandatory for you to link a suitable EVM-compatible wallet address while maintaining your registration as a <a href="https://fuse.gold" target="_blank" class="txGold" rel="noopener noreferrer">fuse.gold</a> member.
<br>
This process will enable us to accurately associate wallet addresses with your existing <a href="https://fuse.gold" target="_blank" class="txGold" rel="noopener noreferrer">fuse.gold</a> accounts. Failure to register or provide a valid wallet address will result in the forfeiture of any referral and VIP rewards that you have earned.
<br>
We appreciate your understanding and cooperation as we enhance our system to better serve you. If you have any questions or require further assistance, please do not hesitate to contact our support team.
<br>
Thank you for your continued trust in our platform.
<br>
Sincerely,
<br>
Your <a href="https://fuse.gold" target="_blank" class="txGold" rel="noopener noreferrer">fuse.gold</a> Team

</p>

        <h4 class="text-center text-dark">Please Confirm fuse.gold registration and provide wallet address</h4>
        <label for="" class="text-dark">Enter the email assigned with fuse.gold account(Must Be Verified).</label>
        <input type="email" name="" id="" v-model="email" class="form-control" placeholder="Enter the email assigned with fuse.gold account">
        <label for="" class="text-dark">Enter the active wallet address (Required for rewards).</label>
        <input type="text" name="" id="" v-model="wallet" class="form-control">
        <button class="btn btn-primary form-control mt-2 mb-5" @click="verify">Verify</button>
            </div>
        </div>
    </div>
    <div class="bg-dark">
        <footer-section-landing></footer-section-landing>
    </div>

  </div>
</template>

<script>

import NavBar from "../components/NavBar.vue";
 import FooterSectionLanding from "../views/FooterSectionLanding.vue";
// import Web3 from 'web3';
// const web3 = new Web3(new Web3.providers.HttpProvider('https://mainnet.infura.io/v3'));
var WAValidator = require('wallet-address-validator');
 import axios from 'axios'
export default {
    components:{
    NavBar, FooterSectionLanding
  },
  data(){
    return {
        email:"",
        wallet:""
        // wallet:"0x0ee1B35526Acb2670fb40fF8313da1fc7f12929e"
    }
  },
  mounted(){
    this.$store.dispatch("setToken")
  },
  methods:{
    async verify(){
        // let wa = false
        let wa = WAValidator.validate(this.wallet,'ethereum');
        if (this.email.length && wa) {
            axios.get("https://fuse.gold/users/get/user/confirm/"+this.email)
            .then(async(res) => {
            if (res.data.user.length) {
                if(res.data.user[0].emailVerified){
                    let dec = await this.$store.dispatch("addconfirm",{email: this.email, wallet: this.wallet, username:res.data.user[0].username})
                    console.log(dec)
                    if(dec == 1){
                        // alert("")
      this.$root.Toast.fire({icon: "success",title:"Your Registration has been confirmed"})

                        this.email = '';
                        this.wallet = ''
                    }else{
                        if (dec == -1) {
                            // alert("")        
      this.$root.Toast.fire({icon: "warning",title:"Registration is already confirmed with this email address"})
                                                
                        }
                        else if(dec == -2)
                        {
                            this.$root.Toast.fire({icon: "warning",title:"Registration is already confirmed with this Wallet address, please choose a different wallet"})
                        }
                        else{
                            // alert("")
      this.$root.Toast.fire({icon: "warning",title:"failed to confirm registration"})

                        }
                    }

                }else{
                    // alert(" ")
      this.$root.Toast.fire({icon: "warning",title:"Eamil is not Verified, please visit fuse.gold to verify email"})

                }
            }else{
                // alert("")
      this.$root.Toast.fire({icon: "warning",title:"No Active record found in fuse.gold with provided email"})

            }
            
        })      
        }else{
            console.log("invalid email OR wallet", this.email, this.wallet, wa)
            // alert("Invalid Email OR Wallet address")
      this.$root.Toast.fire({icon: "warning",title:"Invalid Email OR Wallet address"})

        }
      
    },
  },
}
</script>

<style>

</style>
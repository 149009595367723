<template>
  <div id="app">
    <div v-if="pdfReady">
      <div v-if="$root.Brender" class="pdf-cont-usage" style="width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 99999999999;
    top: 1px;left:0px;">
    <button class="btn btn-secondary text-white" @click="$root.Brender = false" style="position: fixed;
    top: 33px;
    right: 5px;z-index:9999999999;">&#10006;</button>
      <vue-pdf-app page-scale="50" :pdf="arrayBuffer"></vue-pdf-app>

      </div>
    </div>
    
    <div id="nav">
      
    </div>
    <div >
      <router-view/>
    </div>
  </div>
</template>

<script>
import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";
// import Web3 from 'web3'
 export default {
  components:{
    VuePdfApp,
  },
  data(){
  return {
    arrayBuffer:null,
    pdfReady:false,
    timer:null,
    countdownTimer:{hours:0 , minutes:0,seconds:0,},
    targetDateTime: new Date('2023-07-09T15:40:00Z') // Replace with your desired UTC date and time
   
  }
},
async mounted() {
  // this.generateToken();
  setInterval(() => {
    this.timer = this.remainingSeconds(); // Trigger the computed property update every second
    this.countdownTimer = this.Timer()
  }, 1000);
  if(this.$store.state.TOKEN){
this.getPdf()
}else{
  setTimeout(() => {
this.getPdf()
    
  }, 5000);
}
  
},
computed: {
  
},
// computed: {
   methods:{
    async getPdf(){
      try {   
        const response = await fetch(this.$store.state.URL+'/api/get-pdf', {
  method: 'GET', // or 'POST', 'PUT', etc. depending on your API
  headers: {
    'Authorization': `Bearer ${this.$store.state.TOKEN}`, // Include the JWT token in the Authorization header
    'Content-Type': 'application/json'  // Adjust content type as necessary
  }
});
        const arrayBuffer = await response.arrayBuffer();
        this.arrayBuffer = arrayBuffer
        this.pdfReady = true
      } catch (error) {
        console.error('Error fetching or converting PDF:', error);
      }
    },
    hexToAscii(hex) {
  let str = '';
  for (let i = 0; i < hex.length; i += 2) {
    const charCode = parseInt(hex.substr(i, 2), 16);
    if (charCode) {
      str += String.fromCharCode(charCode);
    }
  }
  return str;
},
    Timer() {
    const remainingSeconds = this.remainingSeconds();

    const hours = Math.floor(remainingSeconds / 3600);
    const minutes = Math.floor((remainingSeconds % 3600) / 60);
    const seconds = remainingSeconds % 60;

    return {
      hours: hours < 10 ? `0${hours}` : hours,
      minutes: minutes < 10 ? `0${minutes}` : minutes,
      seconds: seconds < 10 ? `0${seconds}` : seconds
    };
  },
    remainingSeconds() {
      const currentDateTime = new Date();
      const targetTimestamp = this.targetDateTime.getTime();
      const currentTimestamp = currentDateTime.getTime();
      const remainingSeconds = Math.floor((targetTimestamp - currentTimestamp) / 1000);

      return remainingSeconds >= 0 ? remainingSeconds : 0;
    }
   }
  }
//  }
</script>

<style>

</style>

<template>
    <div class="mx-auto nft-back st-shadow"  v-if="nft" style="background: #FFFF;transform: scale(0.7);border-radius: 15px 15px 15px 15px;">
      <div class="fixed-container" v-if="nft.type !== 'image'">
          <div style="height: 250px;">
              <video v-if="nft.type !== 'image'" :src="nft.URI" style="max-width:100%;border-radius: 15px 15px 0px 0px;" playsinline autoplay muted> loop class="d-block mx-auto"></video>
  
          </div>
      </div>

      <div class="fixed-container"  v-else>
          <div style="height: 250px;">
              <img v-if="nft.type == 'image'" :src="nft.URI" alt="" class="d-block mx-auto" srcset="" style="max-width:99%;">
          </div>
          
          </div>
          <h4 class="nft-title cgb" :class="{'text-dark':!$store.state.dark}" style="margin:10% 3% 3% 2%;">
             <span v-if="nft.stats[0] == null">
                Unassigned
             </span>
             <span v-else>
                <span v-if="nft.stats[0][2] !== undefined && nft.stats[3] !== null" :class="{'text-dark':!$store.state.dark}">
                <span v-if="nft.stats[1]">
                    <span> <span v-if="nftbyround[Number(nft.id)] !== undefined">Round {{nftbyround[Number(nft.id)]}}</span> Custom 
                        <!-- <sup v-if="nft.stats[3] !== '0'">Miner{{nft.stats[3]}}x</sup> -->
                        <!-- <sup v-else> Prospectors{{nft.stats[4]}}x </sup> -->
                     </span>
                </span>
                <span v-else>
                <span v-if="nft.stats[0][2] == '1'"><span v-if="nftbyround[Number(nft.id)] !== undefined">Round {{nftbyround[Number(nft.id)]}}</span> Refiner </span> 
                  <span v-if="nft.stats[0][2] == '2'"> <span v-if="nftbyround[Number(nft.id)] !== undefined">Round {{nftbyround[Number(nft.id)]}}</span> Miner</span> 
                  <span v-if="nft.stats[0][2] == '3'"> <span v-if="nftbyround[Number(nft.id)] !== undefined">Round {{nftbyround[Number(nft.id)]}}</span> Prospector</span>
                </span>
              </span>
              <span v-else> Unassigned </span>
             </span>
              #{{ nft.id }} FORTG</h4>
            <div>
                <div >
                <!-- <h4 class="nft-title mt-2 ml-2" style="margin:10% 3% 3% 2%;"> -->
                <!-- Rewards -->
                <!-- <span v-if="nft.rewards !== 0 && nft.rewards !== '0'" class="float-right cgb">{{ (Number(nft.rewards) / 10**GOLDX_DECIMALS).toFixed(4) }} GOLDX</span> -->
                <!-- <span v-else class="float-right cgb"> <timer-comp :nft="nft"></timer-comp> </span> -->
                <!-- </h4> -->
                <h4 class="nft-title mt-2 ml-2" style="margin:10% 3% 3% 2%;" v-if="nft.stats[0] !== null">
                    <span v-if="nft.stats[1]">
                        Units Medium: {{ (nft.stats[3] !== '0') ? nft.stats[3] : 0}}
                        <br>Units Low: {{ (nft.stats[4] !== '0') ? nft.stats[4] : 0}}
                        <br>Round Power: 
<span v-if="nft.stats[3] !== '0'" class="">{{(0.0072 * Number(nft.stats[3]) ).toFixed(5) }}%</span>
                    <span v-if="nft.stats[4] !== '0'"> {{(0.00036 * Number(nft.stats[4]) ).toFixed(5)}}% </span>
                    <br>Global Power: 
<span v-if="nft.stats[3] !== '0'" class="">{{(0.00009013 * Number(nft.stats[3]) ).toFixed(5) }}%</span>
<span v-if="nft.stats[4] !== '0'"> {{(0.0000045 * Number(nft.stats[4]) ).toFixed(5)}}% </span>
                    </span>
                    <span v-else> 
                       <span v-if="nft.stats[0][2] == '3'"> 
                        Units Low: X 1 
<br>Units Medium: X 0
<br> Round Power: 0.00036 % 
<br>Global Power: 0.0000045 %
                       </span>
                       <span v-if="nft.stats[0][2] == '2'">
Units Low: X 0
<br>Units Medium: X 1
<br>Round Power: 0.0072% 
<br>Global Power: 0.00009013% 

                       </span>
                       <span v-if="nft.stats[0][2] == '1'"> 
                        Units Hight: X 1 
<br>Units Medium: X 0
<br> Round Power: 3.8 % 
<br>Global Power: 0.1888 %
                       </span>
                       <!-- <span v-if="nft.stats[0][2] == '3'"> </span> -->
                    </span>
                <!-- Power:
                <span v-if="nft.stats[0][2] !== undefined && nft.stats[3] !== null" :class="{'text-dark':!$store.state.dark}">
                <span v-if="nft.stats[1]" class="float-right cgb" style="font-size: 12px;text-align: end;">
                    <span v-if="nft.stats[3] !== '0'" class="d-block">Miner{{nft.stats[3]}}X({{(0.0072 * Number(nft.stats[3]) ).toFixed(5) }}%)</span>
                    <span v-if="nft.stats[4] !== '0'"> Prospectors{{nft.stats[4]}}X({{(0.00036 * Number(nft.stats[4]) ).toFixed(5)}}%) </span>
                </span>
                <span v-else class="float-right cgb">
                <span v-if="nft.stats[0][2] == '1'">Refiner</span> 
                  <span v-if="nft.stats[0][2] == '2'">0.0072%</span> 
                  <span v-if="nft.stats[0][2] == '3'">0.00036%</span>
                </span>
                   
              </span> -->
                </h4>
                <h4 v-else class="nft-title mt-2 ml-2" style="margin:10% 3% 3% 2%;">Power  <span class="float-right">Unassigned</span> </h4>
                </div>
                <!-- <div style="width: 50%;"></div> -->
            </div>  
  
          <!-- <span class="d-block mx-auto green-button st-shadow-green mx-auto"  style="width: 72%;
    position: absolute;
    left: 14%;" v-if="nft.rewards !== 0 && nft.rewards !== '0' && !nft.stats[1]" @click="claimSingle(nft.id)">
            Claim({{ (Number(nft.rewards) / 10**GOLDX_DECIMALS).toFixed(4) }} GOLDX) 
        </span> -->
          <!-- <span class="d-block green-button my-4" style="width: 72%;
    position: absolute;
    left: 14%;" v-if="ids.includes(nft.id)">Pending Mining Request </span>   -->
      </div>
  </template>
  
  <script>
//   import TimerComp from './TimerComp.vue'
  import {mapState} from 'vuex';
  export default {
    components:{
        // TimerComp
    },
      computed:{
          ...mapState(["GOLDX_DECIMALS",'nftbyround'])
      },
      props:['nft','ids',],
      data(){
          return {
          claiming:false,
          }
      },
      mounted(){
          // const timestamp = 1692439350;
  
      },
  methods:{
      async claimSingle(id){
      this.claiming = true
      await this.$store.dispatch("claimRewards", {id, wallet:this.$store.state.userWallet})
      this.claiming = false
    },
      toDate(t){
          const date = new Date(t * 1000);
  
  return date.toUTCString()
      },
  }
  }
  </script>
  
  <style scoped>
  /* 
  =========================
  Fonts
  =========================
  
  font-family: 'Outfit', sans-serif;
  
  */
  
  @import url('https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;600&display=swap');
  
  /* 
  ========================
  Variables
  ========================
  */
  
  
  :root {
      
      font-size: 15px;
      
      /* Primary */
      --var-soft-blue: hsl(215, 51%, 70%);
      --var-cyan: hsl(178, 100%, 50%);
      /* Neutral */
      --var-main-darkest: hsl(217, 54%, 11%);
      --var-card-dark: hsl(216, 50%, 16%);
      --var-line-dark: hsl(215, 32%, 27%);
      --var-lightest: white;
      
      /* Fonts */
      
      --var-heading: normal normal 600 1.5em/1.6em 'Outfit', sans-serif;
      
      --var-small-heading: normal normal 400 1em/1em 'Outfit', sans-serif;
      
      --var-para: normal normal 300 1em/1.55em 'Outfit', sans-serif;
  }
  
  /* 
  =======================
  Setup
  =======================
  */
  
  html {
      box-sizing: border-box;
  }
  
  *, *::before, *::after {
      box-sizing: inherit;
      margin: 0;
  }
  
  .body {
      background-color: hsl(217, 54%, 11%);
  }
  
  img {
      width: 100%;
      border-radius: 15px 15px 0px 0px;
      display: block;
  }
  
  a {
      color: inherit;
  }
  
  /* 
  
  Eye view
  
  https://i.postimg.cc/9MtT4GZY/view.png' border='0' alt='view */
  
  /*
  =========================
  Font Styling
  =========================
  */
  
  h1 {
      font: normal normal 600 1.5em/1.6em 'Outfit', sans-serif;
      color: white;
      padding: 1.2em 0;
  }
  
  h2 {
      font: normal normal 400 1em/1em 'Outfit', sans-serif;
      color: white;
      /* padding on .coin-base */
  }
  
  p {
      font: normal normal 300 1em/1.55em 'Outfit', sans-serif;
      color: hsl(215, 51%, 70%);
  }
  
  span {
      /* color: white; */
  }
  /* 
  =====================
  Classes
  =====================
  */
  
  /* LAYOUT */
  
  .card-container {
      width: 100%;
      max-width: 400px;
      margin: 2em auto;
      background-color: hsl(216, 50%, 16%);
      border-radius: 15px;
      margin-bottom: 1rem;
      padding: 1rem;
  }
  
  div.flex-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
  }
  
  div.coin-base, .time-left, .card-attribute {
      display: flex;
      align-items: center;
      padding: 1em 0;
  }
  
  .card-attribute {
      padding-bottom: 1.5em;
      border-top: 2px solid hsl(215, 32%, 27%);
  }
  
  a.hero-image-container {
      position: relative;
      display: block;
  }
  
  
  
  /* Details */
  
  img.eye {
      position: absolute;
      width: 100%;
      max-width: 2em;
      top: 44%;
      left: 43%;
  }
  
  @media (min-width:400px) {
    img.eye {
      max-width: 3em;
  }
  }
  
  .hero-image-container::after {
      content: '';
      background-image: url("https://i.postimg.cc/9MtT4GZY/view.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 5rem;
      background-color: hsla(178, 100%, 50%, 0.3);
      width: 100%;
      height: 100%;
      border-radius: 1rem;
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      z-index: 0;
      opacity: 0;
      transition: opacity 0.3s ease-out;
  }
  
  .hero-image-container:hover::after {
    opacity: 1;
  }
  
  .small-image {
      width: 1.2em;
      margin-right: .5em;
  }
  
  .small-avatar {
      width: 2em;
      border-radius: 200px;
      outline: 2px solid white;
      margin-right: 1.4em;
  }
  
  div.attribution {
      margin: 0 auto;
      width: 100%;
      font: normal normal 300 1em/1.55em 'Outfit', sans-serif;
      text-align: center;
      padding: 1.5em 0 4em 0;
      color: hsl(215, 32%, 27%);
  }
  .attribution a {
      color: hsl(215, 51%, 70%);
  }
  
  @media (min-width:600px) {
      .body {
          font-size: 18px;
      }
  }
  .btn-primary{
    font-family: "Calibri";
  
    background-color: #b99653 !important;
    border:1px solid #b99653 !important;
  }
  .fixed-container {
  width: 286px;
  height: 250px;
  overflow: hidden;
  position: relative;
}

.fixed-container img,
.fixed-container video {
  width: 100%;
  height: 100%;
  object-fit: cover; /* This ensures the content is either cropped or zoomed to fit within the container */
}
  </style>
